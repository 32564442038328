import { Link } from "react-router-dom";
import NeedUsIMG from "../../../assets/images/asd1-1-1.png"
import MayklIMG from '../../../assets/images/H6A0966-scaled-psgo70oe3q3ns91lpry26naj72xmqo2yfpwpx4938s.jpg'
import FeliksIMG from '../../../assets/images/H6A1013-1-scaled-psgo6u3irvunizb5s73o76yb1du28scu2tcbk6iugc.jpg'

function HomeThird() {
    return (
        <>
            <section className="protto__bgimg pb-20">
                <main className="max-w-screen-xl mx-auto mt-20">
                    <div className="w-[60%] max-lg:w-full">
                        <h2 className="flex font-montserrat font-bold text-lg text-prottoText max-lg:justify-center max-lg:items-center">
                            <img className="mr-2" src={NeedUsIMG} alt="" />
                            WHO NEEDS US
                        </h2>
                        <h2 className="text-5xl mt-3 font-montserrat font-bold text-prottoText max-lg:text-2xl max-lg:text-center">
                            EXPERIENCE YOU NEED. RESULTS YOU WANT.
                        </h2>
                        <p className="font-montserrat font-medium mt-4 max-lg:px-10">
                            Our employment lawyers are dedicated to working with individuals and businesses in Norfolk,
                            Richmond,
                            Virginia Beach, and all throughout Virginia. Our Virginia employment law attorneys are a highly
                            focused
                            team, and we are selective about the cases that we take on, which ensures every legal matter at our
                            firm
                            receives the time and attention it – and you – deserve. We know that you are counting on us, and we
                            are
                            prepared to put forth our best effort to secure a winning result in your case.
                        </p>
                        <div className="flex space-x-5 max-lg:block max-lg:space-x-0 max-lg:px-10">
                            <button className="max-lg:w-full bg-prottoBG text-white font-montserrat font-bold hover:bg-prottoHover transition-all duration-300 rounded-[5px] mt-5 py-3 px-10">
                                <Link to={'/about'} className="py-3 px-10">
                                    ABOUT US
                                </Link>
                            </button>
                            <button className="max-lg:w-full bg-prottoBG text-white font-montserrat font-bold hover:bg-prottoHover transition-all duration-300 rounded-[5px] mt-5 py-3 px-10 max-lg:px-2">
                                <Link to={'/practise-area'} className="py-3 px-10">
                                    OUR PRACTICE AREAS
                                </Link>
                            </button>
                        </div>
                        <div className="max-lg:before:hidden relative mt-10 before:content-[''] before:absolute before:bg-linkColor before:-left-40 before:top-3 before:h-1 before:w-[19%]">
                            <h5 className="font-montserrat font-semibold text-lg max-lg:text-center">Meet Our Partners</h5>
                        </div>
                        <div className="flex space-x-7 mt-10 max-lg:block max-lg:space-x-0 max-lg:px-10 max-lg:mt-5">
                            <div className="h-fit group">
                                <div className="relative overflow-hidden">
                                    <img className="h-full w-full" src={MayklIMG} alt="" />
                                    <div className="absolute h-full w-full bg-linkColor/40 flex items-center justify-center -bottom-10 group-hover:bottom-0 opacity-0 group-hover:opacity-100 transition-all duration-300">
                                        <button className="font-montserrat bg-transparent border-2 border-white text-white py-2 px-5">
                                            <Link to={'/single-team'} className="py-2 px-5">
                                                View Profile
                                            </Link>
                                        </button>
                                    </div>
                                </div>
                                <h2 className="text-center mt-4 font-montserrat font-semibold text-lg max-lg:mb-5">Maykl
                                    Hovhannesyan</h2>
                            </div>
                            <div className="h-fit group">
                                <div className="relative overflow-hidden">
                                    <img className="h-full w-full" src={FeliksIMG} alt="" />
                                    <div className="absolute h-full w-full bg-linkColor/40 flex items-center justify-center -bottom-10 group-hover:bottom-0 opacity-0 group-hover:opacity-100 transition-all duration-300">
                                        <button className="font-montserrat bg-transparent border-2 border-white text-white py-2 px-5">
                                            <Link to={'/single-team'} className="py-2 px-5">
                                                View Profile
                                            </Link>
                                        </button>
                                    </div>
                                </div>
                                <h2 className="text-center mt-4 font-montserrat font-semibold text-lg">Feliks Hovakimyan</h2>
                            </div>
                        </div>
                    </div>
                </main>
            </section>
        </>
    );
}

export default HomeThird