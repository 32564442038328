import Post1IMG from '../assets/images/ITS-IMPORTANT-TO-KNOW-1-300x251.png'
import Post2IMG from '../assets/images/unnamed-300x200.jpg'
import Post3IMG from '../assets/images/State-Support-to-the-IT-Businesses-4-300x251.png'
import { Link } from 'react-router-dom';

function Blog() {
    return (
        <>
            <section className="bg-prottoBG h-[500px] w-full flex justify-center items-center">
                <h2 className="font-montserrat font-extralight text-6xl text-white max-lg:text-2xl">Publications</h2>
            </section>
            <section className="mx-auto max-w-screen-xl mt-20 max-lg:px-10">
                <div className="flex space-x-5 mt-10 max-lg:block max-lg:space-x-0 max-lg:mx-auto">
                    <div className="w-1/3 h-auto max-lg:w-full max-lg:mb-3">
                        <Link to={'/single-post'}>
                            <div className="h-[251px]">
                                <img className="h-full w-full object-cover" src={Post1IMG} alt="" />
                            </div>
                            <h2 className="mt-2 font-montserrat font-bold text-xl p-2 max-lg:text-lg">Important Amendments for Legal
                                Entities</h2>
                        </Link>
                        <p className="p-2 font-montserrat">
                            ❔Are you keeping up with legislative amendments? ❕ Legal entities are, hereinafter, obliged not only
                            to submit a declaration of
                        </p>
                        <Link to={'/single-post'} className="p-2 text-linkColor font-montserrat font-semibold text-sm">Read More »</Link>
                    </div>
                    <div className="w-1/3 h-auto max-lg:w-full max-lg:mb-3">
                        <Link to={'/single-post'}>
                            <div className="h-[251px]">
                                <img className="h-full w-full object-cover" src={Post2IMG} alt="" />
                            </div>
                            <h2 className="mt-2 font-montserrat font-bold text-xl p-2 max-lg:text-lg">Effects of Share Dilution on
                                Your Startup
                            </h2>
                        </Link>
                        <p className="p-2 font-montserrat">
                            As exciting as fundraising is for the financial benefits it brings to the table, fundraising
                            decisions come with long-term implications,
                        </p>
                        <Link to={'/single-post'} className="p-2 text-linkColor font-montserrat font-semibold text-sm">Read More »</Link>
                    </div>
                    <div className="w-1/3 h-auto max-lg:w-full max-lg:mb-3">
                        <Link to={'/single-post'}>
                            <div className="h-[251px]">
                                <img className="h-full w-full object-cover" src={Post3IMG} alt="" />
                            </div>
                            <h2 className="mt-2 font-montserrat font-bold text-xl p-2 max-lg:text-lg">10 BILLION ARMENIAN DRAMS AS
                                STATE SUPPORT TO
                                IT COMPANIES | 10 МЛРД АРМЯНСКИХ ДРАМОВ НА ГОСУДАРСТВЕННУЮ ПОДДЕРЖКУ ИТ-КОМПАНИЙ</h2>
                        </Link>
                        <p className="p-2 font-montserrat">
                            The Government of the Republic of Armenia approved new support procedures for IT companies and
                            private entrepreneurs working in the
                        </p>
                        <Link to={'/single-post'} className="p-2 text-linkColor font-montserrat font-semibold text-sm">Read More »</Link>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Blog