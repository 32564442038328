import MayklIMG from '../assets/images/H6A0966-scaled.jpg'
import FeliksIMG from '../assets/images/H6A1013-1-scaled-psgo6u3irvunizb5s73o76yb1du28scu2tcbk6iugc.jpg'
import AlexIMG from '../assets/images/Alex.jpeg'
import DonaraIMG from '../assets/images/photo1658818643.jpeg'
import { Link } from 'react-router-dom';

function Team() {
    return (
        <>
            <section className="team__bg bg-cover bg-[center] h-[500px] w-full flex justify-center items-center">
                <h2 className="font-montserrat font-semibold text-6xl uppercase text-white">Our Team</h2>
            </section>
            <section className="max-w-screen-xl mx-auto mt-20 max-lg:px-10">
                <main className="flex flex-wrap justify-evenly max-lg:block">
                    <Link className="w-[40%] max-lg:w-full h-auto" to={'/single-team'}>
                        <div className="border-b-4 border-prottoHover hover:bg-prottoHover transition-all duration-300 overflow-hidden">
                            <div className="h-[650px] max-lg:h-[400px]">
                                <img className="w-full h-full object-cover hover:scale-[1.02] transition-all duration-300" src={MayklIMG} alt="" />
                            </div>
                            <div className="bg-transparent py-5 flex justify-center items-center text-center">
                                <div className="font-montserrat font-bold text-xl">
                                    <h2>Maykl Hovhannesyan</h2>
                                    <span className="font-medium text-lg">Partner</span>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link className="w-[40%] max-lg:w-full max-lg:m-20" to={'/single-team'}>
                        <div className="border-b-4 border-prottoHover hover:bg-prottoHover transition-all duration-300 overflow-hidden">
                            <div className="h-[650px] max-lg:h-[400px]">
                                <img className="w-full h-full object-cover hover:scale-[1.02] transition-all duration-300" src={FeliksIMG} alt="" />
                            </div>
                            <div className="bg-transparent py-5 flex justify-center items-center text-center">
                                <div className="font-montserrat font-bold text-xl">
                                    <h2>Feliks Hovakimyan</h2>
                                    <span className="font-medium text-lg">Partner</span>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link className="w-[40%] max-lg:w-full max-lg:mt-0 mt-20" to={'/single-team'}>
                        <div className="border-b-4 border-prottoHover hover:bg-prottoHover transition-all duration-300 overflow-hidden">
                            <div className="h-[650px] max-lg:h-[400px]">
                                <img className="w-full h-full object-cover hover:scale-[1.02] transition-all duration-300" src={AlexIMG} alt="" />
                            </div>
                            <div className="bg-transparent py-5 flex justify-center items-center text-center">
                                <div className="font-montserrat font-bold text-xl">
                                    <h2>Alex Harutyunyan</h2>
                                    <span className="font-medium text-lg">Of Counsel Attorney</span>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <div className="w-[40%] max-lg:w-full max-lg:mt-0 mt-20">
                        <div className="border-b-4 border-prottoHover hover:bg-prottoHover transition-all duration-300 overflow-hidden">
                            <div className="h-[650px] max-lg:h-[400px]">
                                <img className="w-full h-full object-cover hover:scale-[1.02] transition-all duration-300" src={DonaraIMG} alt="" />
                            </div>
                            <div className="bg-transparent py-5 flex justify-center items-center text-center">
                                <div className="font-montserrat font-bold text-xl">
                                    <h2>Donara Ghazaryan</h2>
                                    <span className="font-medium text-lg">Legal Assistant</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </section>
            <section className="relative max-lg:static">
                <main className="max-lg:before:hidden meet__bg bg-no-repeat bg-cover bg-[center] max-lg:h-auto max-lg:p-10 w-full h-[575px] mt-20 flex justify-center items-center">
                    <div className="w-1/2 text-center max-lg:w-full">
                        <h2 className="flex justify-center items-center font-montserrat font-bold text-xl max-lg:text-lg text-white">
                            {/* <img className="mr-2" src="/public/images/asd1-1-1.png" alt="" /> */}
                            LET’S MEET.
                        </h2>
                        <h2 className="font-montserrat font-bold text-4xl text-white mt-2">
                            SPEAK WITH OUR EXPERT NOW.
                        </h2>
                        <p className="font-montserrat text-white font-medium mt-8">
                            For more information about what we do for individuals and businesses, please contact
                            us to arrange an initial consultation. To speak with one of our experts,
                            call +(374) 60730740 or schedule a call online today.
                        </p>
                    </div>
                </main>
                <div className="bg-linkColor h-20 w-full" />
            </section>
        </>
    );
}

export default Team