
function HomeEight() {
    return (
        <>
            <section className="bg-prottoBG w-full h-auto p-20 max-lg:p-10">
                <main className="max-w-screen-xl mx-auto flex space-x-5 max-lg:block max-lg:space-x-0">
                    <div className="w-1/3 max-lg:w-full max-lg:mb-10">
                        <h2 className="font-montserrat font-bold text-4xl text-white max-lg:text-2xl">Subscribe for Updates</h2>
                        <p className="font-montserrat font-medium text-white max-lg:mt-5">
                            Get updates delivered right to your inbox. You can
                            unsubscribe at any time.
                        </p>
                    </div>
                    <div className="w-2/3 max-lg:w-full">
                        <form action="true">
                            <label htmlFor="subInp" aria-required="true" className="text-white font-montserrat">Email</label>
                            <input type="email" id="subInp" required placeholder="example@mail.com" className="w-full h-10 p-2 font-montserrat" />
                            <input type="submit" defaultValue="Send" className="font-montserrat bg-linkColor px-5 py-2 mt-3 text-white cursor-pointer transition-all duration-300 hover:bg-prottoBG" />
                        </form>
                    </div>
                </main>
            </section>
        </>
    );
}

export default HomeEight