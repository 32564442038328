import { Link } from "react-router-dom";


function PractiseAreaArchive() {
    return (
        <>
            <section className="bg-prottoBG h-[500px] w-full flex justify-center items-center">
                <h2 className="font-montserrat font-extralight text-6xl text-white max-lg:text-2xl">PRACTISE <span className="border-linkColor border-b-8 max-lg:border-b-4">AREAS</span></h2>
            </section>
            <section className="max-w-screen-xl mx-auto mt-5">
                <div className="flex space-x-5 max-lg:space-x-0 max-lg:px-10 max-lg:block">
                    <Link className="w-1/3 max-lg:w-full" to={'/single-practise-area'}>
                        <div className="shadow-gray-500 max-lg:shadow-lg shadow-xl border-t-4 border-linkColor font-montserrat px-5 h-[150px] flex justify-start items-center font-bold text-xl">
                            <h2>M&amp;A Advising</h2>
                        </div>
                    </Link>
                    <Link className="w-1/3 max-lg:w-full" to={'/single-practise-area'}>
                        <div className="shadow-gray-500 max-lg:shadow-lg max-lg:mt-5 shadow-xl border-t-4 border-linkColor font-montserrat px-5 h-[150px] flex justify-start items-center font-bold text-xl">
                            <h2>Accounting &amp; Bookkeeping</h2>
                        </div>
                    </Link>
                    <Link className="w-1/3 max-lg:w-full" to={'/single-practise-area'}>
                        <div className="shadow-gray-500 max-lg:shadow-lg max-lg:mt-5 shadow-xl border-t-4 border-linkColor font-montserrat px-5 h-[150px] flex justify-start items-center font-bold text-xl">
                            <h2>Public Law and Regulatory</h2>
                        </div>
                    </Link>
                </div>
            </section>
        </>
    );
}

export default PractiseAreaArchive