import { Link } from "react-scroll";
import TeamContentIMG from '../../../assets/images/asd1-1-1.png'
import TeamSinglePublications from "./publications";

function TeamContent() {
    return (
        <>
            <section className="flex mt-20 max-w-screen-xl mx-auto space-x-10 max-lg:space-x-0 max-lg:px-10 max-lg:mt-10">
                <div className="w-[30%] flex flex-col space-y-5 max-lg:hidden">
                    <button className="px-5 py-3 bg-transparent font-montserrat border border-linkColor text-prottoText font-bold text-2xl transition-all duration-300 hover:text-linkColor">
                        <Link activeClass='ll' to={'overview'} spy={true} smooth={true} duration={700}>Overview</Link>
                    </button>
                    <button className="px-5 py-3 bg-transparent font-montserrat border border-linkColor text-prottoText font-bold text-2xl transition-all duration-300 hover:text-linkColor">
                        <Link activeClass='ll' to={'expertise'} spy={true} smooth={true} duration={700}>Expertise</Link>
                    </button>
                    <button className="px-5 py-3 bg-transparent font-montserrat border border-linkColor text-prottoText font-bold text-2xl transition-all duration-300 hover:text-linkColor">
                        <Link activeClass='ll' to={'educationAndAdmissions'} spy={true} smooth={true} duration={700}>Education and Admissions</Link>
                    </button>
                    <button className="px-5 py-3 bg-transparent font-montserrat border border-linkColor text-prottoText font-bold text-2xl transition-all duration-300 hover:text-linkColor">
                        <Link activeClass='ll' to={'publications'} spy={true} smooth={true} duration={700}>Publications</Link>
                    </button>
                </div>
                <div className="w-[70%] max-lg:w-full">
                    <div id="overview" className="font-montserrat">
                        <h2 className="flex font-semibold text-2xl max-lg:text-lg text-prottoText">
                            <img className="mr-2" src={TeamContentIMG} alt="" />
                            Overview
                        </h2>
                        <p className="font-medium mt-5">
                            Maykl Hovhannesyan has a reputable expertise in all aspects of corporate law and a practice focused
                            on
                            Corporate law, Mergers and Acquisitions and Data Protection. His industry knowledge encompasses
                            highly
                            regulated sectors such as energy and construction as well as technology, manufacturing, and
                            investments.
                            <br />
                            <br />
                            Providing guidance and assistance on general corporate commercial matters as well as transactions,
                            Maykl
                            advises on business structuring and organization, restructurings and reorganizations, mergers and
                            acquisitions. He also works with his clients on the preparation and negotiation of contracts,
                            agreements
                            and corporate documents. In addition, Maykl performs due diligence and manages closing deliveries on
                            complex transactions.
                            <br />
                            <br />
                            With insight into the financial and legal challenges facing businesses in the public and private
                            spaces,
                            Maykl finds practical solutions to day-to-day commercial challenges, including ongoing compliance
                            with
                            Armenian securities and corporate laws as well as commercial arrangements.
                            <br />
                            <br />
                            With deep experience acting for professional service firms, clients trust Maykl’s thorough
                            understanding
                            of business law and his ability to navigate the complex laws and regulations that affect their
                            companies
                            and partnerships.
                        </p>
                    </div>
                    <div id="expertise" className="font-montserrat mt-10">
                        <h2 className="flex font-semibold text-2xl max-lg:text-lg text-prottoText">
                            <img className="mr-2" src={TeamContentIMG} alt="" />
                            Expertise
                        </h2>
                        <h2 className="border-b border-black text-2xl font-medium mt-5 pb-3 w-[30%]">Practices</h2>
                        <ul className="font-medium mt-5 list-disc marker:text-linkColor marker:text-xl list-inside">
                            <li className="transition-all duration-300 hover:text-linkColor"><a href>Corporate / M&amp;A</a></li>
                            <li className="transition-all duration-300 hover:text-linkColor"><a href>IT &amp; Data Protection</a>
                            </li>
                            <li className="transition-all duration-300 hover:text-linkColor"><a href>Investment Law</a></li>
                            <li className="transition-all duration-300 hover:text-linkColor"><a href>Energy Law</a></li>
                            <li className="transition-all duration-300 hover:text-linkColor"><a href>Labor &amp; Employment</a></li>
                        </ul>
                    </div>
                    <div id="educationAndAdmissions" className="font-montserrat mt-10">
                        <h2 className="flex font-semibold text-2xl max-lg:text-lg text-prottoText">
                            <img className="mr-2" src={TeamContentIMG} alt="" />
                            Education and Admissions
                        </h2>
                        <div className="px-5 mt-5">
                            <p className="font-bold">2020</p>
                            <div className="px-5">
                                <ul className="font-medium mt-5 list-disc marker:text-xl list-outside">
                                    <li>
                                        Master of Laws (LL.M) / Specialization in Corporate Law <br />
                                        American University of Armenia
                                    </li>
                                </ul>
                            </div>
                            <p className="font-bold mt-5">2020</p>
                            <div className="px-5">
                                <ul className="font-medium mt-5 list-disc marker:text-xl list-outside">
                                    <li>
                                        Certificate in Corporate Governance <br />
                                        University of Munich LMU
                                    </li>
                                </ul>
                            </div>
                            <p className="font-bold mt-5">2018</p>
                            <div className="px-5">
                                <ul className="font-medium mt-5 list-disc marker:text-xl list-outside">
                                    <li>
                                        Bachelor of Arts / Specialization in Public Relations <br />
                                        American University of Armenia
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div id="publications" className="font-montserrat mt-10">
                        <h2 className="flex font-semibold text-2xl max-lg:text-lg text-prottoText">
                            <img className="mr-2" src={TeamContentIMG} alt="" />
                            Publications
                        </h2>
                        <TeamSinglePublications />
                    </div>
                </div>
            </section>
        </>
    );
}

export default TeamContent
