import SinglePostIMG from '../assets/images/ITS-IMPORTANT-TO-KNOW-1-300x251.png'

function SinglePost() {
    return (
        <>
            <section className='flex justify-between h-[500px] bg-prottoBG py-4 max-lg:block max-lg:h-auto max-lg:py-0'>
                <div className="w-[60%] max-lg:w-full">
                    <img className="h-full w-full object-contain" src={SinglePostIMG} alt="" />
                </div>
                <div className='w-[40%] max-lg:w-full max-lg:text-center max-lg:block font-montserrat text-white flex justify-start items-center'>
                    <h2 className="text-4xl font-bold max-lg:text-xl max-lg:p-5 ">Important Amendments for Legal Entities</h2>
                </div>
            </section>
            <section className="max-w-screen-xl mx-auto mt-10 max-lg:mt-0">
                <div className="flex justify-center items-center">

                </div>
                <div className="font-montserrat text-center mt-10">

                    <p className="font-medium text-left mt-10 max-lg:px-10">
                        ❔Are you keeping up with legislative amendments?
                        <br />
                        <br />
                        ❕ Legal entities are, hereinafter, obliged not only to submit a declaration of their real beneficiaries
                        at the time of registration before the state authority, but also, to submit the following document(s)
                        every calendar
                        year before February 20:
                        <br />
                        <br />
                        — Confirmation that as of December 31 of the previous year, no changes have been made in the company’s
                        latest declaration of beneficial owners (UBO); or
                        <br />
                        <br />
                        — Submit a new declaration of UBOs with relevant changes (in case any changes have occurred).
                        <br />
                        <br />
                        ❔ How is the confirmation or the new declaration submitted?
                        <br />
                        <br />
                        ➡️ The director (CEO) of the legal entity or the person authorized by the latter must access the
                        electronic system of declaration of beneficial owners (https://bo.e-register.am/am/auth), fill in the
                        appropriate form and confirm it with an electronic signature.
                        <br />
                        <br />
                        ❔ What is the legal consequence of not complying with the mentioned requirement?
                        <br />
                        <br />
                        ➡️ If this requirement is not complied with for three consecutive years, the authorized body is entitled
                        to apply to the court with the claim to dissolve the legal entity.
                        <br />
                        <br />
                        ❔How Protto can help?
                        <br />
                        <br />
                        Our lawyers can assist you in this matter by preparing the necessary paperwork for the submission of the
                        declaration or the confirmation document and ensure your company’s compliance in the shortest period of
                        time.
                        <br />
                        <br />
                        ❗ Follow our page and be constantly informed about essential legislative amendments.
                        <br />
                        <br />
                        _______________________________________________________________________
                        <br />
                        <br />
                        ❔Հասցնու՞մ եք հետևել օրենսդրական փոփոխություններին:
                        <br />
                        <br />
                        ❕Իրավաբանական անձինք պարտավոր են ոչ միայն ռեգիստրում գրանցման ժամանակ ներկայացնել իրենց իրական
                        շահառուների վերաբերյալ հայտարարագիր, այլև այսուհետ՝ յուրաքանչյուր տարի մինչև փետրվարի 20-ը պարտավոր են
                        գործակալություն ներկայացնել՝
                        <br />
                        <br />
                        – Հաստատում այն մասին, որ նախորդ տարվա դեկտեմբերի 31-ի դրությամբ ընկերության իրական շահառուների
                        վերաբերյալ վերջին հայտարարագրում փոփոխություններ չկան,
                        <br />
                        <br />
                        – փոփոխություններ լինելու պարագայում, ներկայացնել նոր հայտարարագիր՝ համապատասխան փոփոխոխություններով։
                        <br />
                        <br />
                        ❔Ինչպե՞ս են ներկայացվում հաստատումը կամ նոր հայտարարագիրը։
                        <br />
                        <br />
                        ➡️ Իրավաբանական անձի տնօրենը կամ վերջինիս կողմից լիազորված անձը պետք է մուտք գործի իրական շահառուների
                        հայտարարագրման էլեկտրոնային համակարգ (https://bo.e-register.am/am/auth), լրացնի համապատասխան ձևաթուղթը և
                        էլելտրոնային ստորագրությամբ հաստատի այն։
                        <br />
                        <br />
                        ❔Ո՞րն է նշված պահանջի չպահպանման իրավական հետևանքը։
                        <br />
                        <br />
                        ➡️ Այս պահանջը երեք տարի անընդմեջ չկատարելու դեպքում, գործակալությունը կարող է իրավաբանական անձին
                        լուծարելու պահանջով դիմել դատարան։
                        <br />
                        <br />
                        ❗Հետևեք մեր էջին և մշտապես տեղեկացված եղեք օրենսդրական փոփոխություններին:
                        <br />
                        <br />
                        _______________________________________________________________________
                        <br />
                        <br />
                        ❔Следите ли вы за изменениями в законодательстве?
                        <br />
                        <br />
                        ❕ Юридические лица обязаны не только подать декларацию о своих бенефициарных собственников при
                        регистрации в реестре, но и впредь ежегодно до 20 февраля обязаны представлять в агентство:
                        <br />
                        <br />
                        — Подтверждение того, что по состоянию на 31 декабря предыдущего года в последней декларации
                        бенефициарных собственников компании нет изменений,
                        <br />
                        <br />
                        — в случае изменений подать новую декларацию с соответствующими изменениями.
                        <br />
                        <br />
                        ❔ Как подается подтверждение или новая декларация?
                        <br />
                        <br />
                        ➡️ Руководитель юридического лица или уполномоченное им лицо должны получить доступ к электронной
                        системе декларирования бенефициарных собственников (https://bo.e-register.am/am/auth), заполнить
                        соответствующую форму и подтвердить ее с электронной подписью.
                        <br />
                        <br />
                        ❔ Каковы юридические последствия несоблюдения указанного требования?
                        <br />
                        <br />
                        ➡️ Если это требование не выполняется три года подряд, агентство может обратиться в суд с требованием о
                        ликвидации юридического лица.
                        <br />
                        <br />
                        ❔Как Protto может помочь?
                        <br />
                        <br />
                        Наши юристы могут помочь Вам в этом вопросе, подготовив необходимые документы для подачи декларации в
                        короткие сроки.
                        <br />
                        <br />
                        ❗ Подписывайтесь на нашу страницу и будьте постоянно в курсе законодательных изменений.
                    </p>
                </div>
                <div className="flex justify-center items-center mt-10">
                    <div className="flex space-x-5">
                        <div className="flex justify-center">
                            <button type="button" data-mdb-ripple="true" data-mdb-ripple-color="light" className="inline-block px-6 py-2.5 mb-2 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out" style={{ backgroundColor: '#1877f2' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="w-7 h-7">
                                    <path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                                </svg>
                            </button>
                        </div>
                        <div className="flex justify-center">
                            <button type="button" data-mdb-ripple="true" data-mdb-ripple-color="light" className="inline-block px-6 py-2.5 mb-2 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out" style={{ backgroundColor: '#0077b5' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-7 h-7">
                                    <path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SinglePost