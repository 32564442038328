import { Link } from "react-router-dom";


function Digital() {
    return (
        <>
            <div>
                <section className="digital__bg h-[500px] bg-no-repeat bg-contain bg-[#1E1D22] bg-[center] w-full"></section>
                <section className="bg-[#1E1D22] pb-20 max-lg:px-10">
                    <main className="max-w-screen-xl mx-auto">
                        <div className="flex justify-center items-center">
                            <h2 className="font-montserrat font-semibold text-5xl text-white">Coming Soon</h2>
                        </div>
                        <div className="container mt-20">
                            <div id="countdown">
                                <ul className="flex font-montserrat text-white justify-between">
                                    <li className="text-xl text-center">
                                        <div className="text-5xl" id="days" />Days
                                    </li>
                                    <li className="text-xl text-center">
                                        <div className="text-5xl" id="hours" />Hours
                                    </li>
                                    <li className="text-xl text-center">
                                        <div className="text-5xl" id="minutes" />Minutes
                                    </li>
                                    <li className="text-xl text-center">
                                        <div className="text-5xl text-center" id="seconds" />Seconds
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="container mt-32 max-lg:mt-20">
                            <ul className="flex justify-between max-lg:block w-full">
                                <li className="w-1/3 max-lg:w-full">
                                    <Link className="flex justify-center items-center max-lg:justify-start max-lg:items-start max-lg:mb-5 space-x-5" to={'https://google.com/maps/place/23,+2+Երվանդ+Քոչարի+փողոց,+Երևան,+Հայաստան/@40.1729974,44.5179421,17z/data=!3m1!4b1!4m5!3m4!1s0x406abcf4136c35bb:0xc8f38472dde51a06!8m2!3d40.1729933!4d44.5201308'} target={'_blank'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" className="w-10 h-10">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" />
                                        </svg>
                                        <span className="text-white font-montserrat text-xl">23/2 Yervand Kochar Street, Yerevan,
                                            Armenia</span>
                                    </Link>
                                </li>
                                <li className="w-1/3 max-lg:w-full">
                                    <Link className="flex justify-center items-center max-lg:justify-start max-lg:items-start max-lg:mb-5 space-x-5" to={'tel:+374 60730740'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" className="w-10 h-10">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                        </svg>
                                        <span className="text-white font-montserrat text-xl">+374 60730740</span>
                                    </Link>
                                </li>
                                <li className="w-1/3 max-lg:w-full">
                                    <Link className="flex justify-center items-center max-lg:justify-start max-lg:items-start max-lg:mb-5 space-x-5" to={'mailto:info@protto.am'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" className="w-10 h-10">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z" />
                                        </svg>
                                        <span className="text-white font-montserrat text-xl">info@protto.am</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </main>
                </section>
            </div>
        </>
    );
}

export default Digital