import { Link } from 'react-router-dom';
import ClientsIMG from '../assets/images/asd1-1-1.png'

function Clients() {
    return (
        <>
            <section className="individuals__bg bg-cover bg-[center] h-[500px] w-full flex justify-center items-center">
                <h2 className="font-montserrat font-extralight text-6xl text-white max-lg:text-2xl">WHAT OUR CLIENTS SAY</h2>
            </section>
            <section className="max-w-screen-xl mx-auto mt-20 max-lg:px-10">
                <div className="flex space-x-5 max-lg:block max-lg:space-x-0">
                    <div className="w-2/3 max-lg:w-full">
                        <div className="font-montserrat font-medium text-lg border-b border-black pb-5">
                            <p>
                                «Professional and Efficient. The team at Protto executed relocation of my company expeditiously
                                and fairly. Highly recommend their services.»
                            </p>
                            <h2 className="flex mt-5">
                                <img className="mr-2" src={ClientsIMG} alt="" />
                                Sergey Savinov, CEO and Co-Founder at FitOn
                            </h2>
                        </div>
                        <div className="font-montserrat font-medium text-lg border-b border-black pb-5 mt-5">
                            <p>
                                «My experience with Protto Legal has been excellent. My attorney, Feliks, gave my case all the
                                time and attention that was needed to be successful in court. I very much appreciate the
                                knowledge and support I received through Protto.»
                            </p>
                            <h2 className="flex mt-5">
                                <img className="mr-2" src={ClientsIMG} alt="" />
                                Vardan Vardanyan
                            </h2>
                        </div>
                        <div className="font-montserrat font-medium text-lg border-b border-black pb-5 mt-5">
                            <p>
                                «Everyone at Protto is professional, knowledgeable and very results-oriented. I highly recommend
                                this firm especially when it comes to setting up businesses in Armenia.»
                            </p>
                            <h2 className="flex mt-5">
                                <img className="mr-2" src={ClientsIMG} alt="" />
                                Dmitriy Tarasov, Founder at Easy-SMS
                            </h2>
                        </div>
                        <div className="font-montserrat font-medium text-lg border-b border-black pb-5 mt-5">
                            <p>
                                «The team at Protto has been a great value-add in terms of professionalism and thoroughness. Our
                                lawyer Maykl understands perfectly how to approach a business relationship and how to get things
                                done!»
                            </p>
                            <h2 className="flex mt-5">
                                <img className="mr-2" src={ClientsIMG} alt="" />
                                Grigor Grigoryan, CEO at Beegraphy Corp
                            </h2>
                        </div>
                        <div className="font-montserrat font-medium text-lg mt-5">
                            <p>
                                «I have been recommended to render Protto’s legal and accounting services since I arrived to
                                Armenia. They handled opening of my legal entity in a matter of hours and I am very satisfied
                                with services and the communication line they provide.»
                            </p>
                            <h2 className="flex mt-5">
                                <img className="mr-2" src={ClientsIMG} alt="" />
                                John Brennel, Private Entrepreneur
                            </h2>
                        </div>
                    </div>
                    <div className="w-1/3 max-lg:w-full max-lg:mt-5">
                        <div className="w-full h-full">
                            <div className="w-full mb-2">
                                <Link className="protto__link" to={'/individuals'}>
                                    What we do for
                                    <span className="protto__span">Individuals
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                        </svg>
                                    </span>
                                </Link>
                            </div>
                            <div className="w-full mb-2">
                                <Link className="protto__link" to={'/business'}>
                                    What we do for
                                    <span className="protto__span">Bussinesses
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                        </svg>
                                    </span>
                                </Link>
                            </div>
                            <div className="mt-2">
                                <Link to={'/single-post'}>
                                    <div className="font-montserrat py-3">
                                        <h2 className="font-bold">Important Amendments for Legal Entities</h2>
                                        <span>13.01.2023</span>
                                        <p className="mt-3">❔Are you keeping up with</p>
                                    </div>
                                </Link>
                                <Link to={'/single-post'}>
                                    <div className="font-montserrat py-3">
                                        <h2 className="font-bold">Effects of Share Dilution on Your Startup</h2>
                                        <span>21.12.2022</span>
                                        <p className="mt-3">As exciting as fundraising is</p>
                                    </div>
                                </Link>
                                <Link to={'/single-post'}>
                                    <div className="font-montserrat py-3">
                                        <h2 className="font-bold">10 BILLION ARMENIAN DRAMS AS STATE SUPPORT TO IT COMPANIES | 10
                                            МЛРД АРМЯНСКИХ ДРАМОВ НА ГОСУДАРСТВЕННУЮ ПОДДЕРЖКУ ИТ-КОМПАНИЙ</h2>
                                        <span>06.12.2022</span>
                                        <p className="mt-3">The Government of the Republic</p>
                                    </div>
                                </Link>
                                <Link to={'/single-post'}>
                                    <div className="font-montserrat py-3">
                                        <h2 className="font-bold">UATE «Members Meetup»</h2>
                                        <span>09.11.2022</span>
                                        <p className="mt-3">On November 4, Protto Legal</p>
                                    </div>
                                </Link>
                                <Link to={'/single-post'}>
                                    <div className="font-montserrat py-3">
                                        <h2 className="font-bold">Legal Updates for July/August/September</h2>
                                        <span>24.10.2022</span>
                                        <p className="mt-3">LEGAL UPDATES FOR JULY /</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative max-lg:static mt-40 max-lg:mt-10">
                <main className="max-lg:before:hidden help__bg helpBG__before bg-no-repeat bg-cover bg-[center] max-lg:h-auto max-lg:p-10 w-full h-[575px] mt-20 flex justify-center items-center">
                    <div className="w-1/2 text-center max-lg:w-full">
                        <h2 className="flex justify-center items-center font-montserrat font-bold text-xl max-lg:text-lg text-prottoText">
                            <img className="mr-2" src={ClientsIMG} alt="" />
                            LET’S MEET.
                        </h2>
                        <h2 className="font-montserrat font-bold text-4xl text-prottoText mt-2">
                            SPEAK WITH OUR EXPERT NOW.
                        </h2>
                        <p className="font-montserrat font-medium mt-8">
                            For more information about what we do for individuals and businesses, please contact
                            us to arrange an initial consultation. To speak with one of our experts,
                            call +(374) 60730740 or schedule a call online today.
                        </p>
                    </div>
                </main>
                <div className="bg-linkColor h-20 w-full" />
            </section>
        </>
    );
}

export default Clients