import { Link } from "react-router-dom";
import TeamContent from "./components/teamSingle/content";


function TeamSingle() {
    return (
        <>
            <section className="bg-[#2A222A] pt-5 max-lg:pb-4 h-[650px] max-lg:h-auto">
                <div className="w-[80%] mx-auto flex max-lg:flex-col-reverse max-lg:space-x-0 space-x-10">
                    <div className="font-montserrat flex max-lg:w-full max-lg:text-center justify-center flex-col text-white w-1/2">
                        <h2 className="font-bold text-4xl max-lg:text-2xl">Maykl Hovhannesyan</h2>
                        <h2 className="font-semibold text-2xl mt-5 max-lg:text-sm">Partner, Business Law | Mergers &amp; Acquisitions
                        </h2>
                        <ul className="w-full mt-5">
                            <li className="hover:text-prottoHover transition-all duration-300 max-lg:text-lg text-xl">
                                <Link className="stroke-prottoHover space-x-3 flex items-center" target={'_blank'} to={'https://goo.gl/maps/ECokh7XCLdZV6LPT6'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="prottoHover" className="w-10 h-10 max-lg:w-7 max-lg:h-7">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" />
                                    </svg>
                                    <span className="font-montserrat font-medium">Yerevan</span>
                                </Link>
                            </li>
                            <li className="hover:text-prottoHover transition-all duration-300 text-xl mt-2 max-lg:text-lg">
                                <Link className="fill-prottoHover space-x-3 flex items-center" to={'tel:+37496949979'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="prottoHover" viewBox="0 0 24 24" strokeWidth="1.5" stroke="none" className="w-10 h-10 max-lg:w-7 max-lg:h-7">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                    </svg>
                                    <span className="font-montserrat font-medium">+374 96949979</span>
                                </Link>
                            </li>
                            <li className="hover:text-prottoHover transition-all duration-300 text-xl mt-2 max-lg:text-lg">
                                <Link className="stroke-prottoHover space-x-3 flex items-center" to={'Mayklhovhannesyan@protto.am'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="prottoHover" className="w-10 h-10 max-lg:w-7 max-lg:h-7">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z" />
                                    </svg>
                                    <span className="font-montserrat font-medium">Mayklhovhannesyan@protto.am</span>
                                </Link>
                            </li>
                        </ul>
                        <h2 className="mt-5 text-xl max-lg:text-start">Expertise</h2>
                        <div className="flex flex-wrap max-lg:block max-lg:text-start items-start w-full">
                            <div className="mr-2">
                                <button className="bg-prottoBG text-white font-montserrat font-bold hover:bg-prottoHover transition-all duration-300 rounded-[5px] mt-5 py-3 px-5">
                                    <Link className="py-3" to={'/single-practise-area'}>
                                        Corporate / M&amp;A
                                    </Link>
                                </button>
                            </div>
                            <div className="mr-2">
                                <button className="bg-prottoBG text-white font-montserrat font-bold hover:bg-prottoHover transition-all duration-300 rounded-[5px] mt-5 py-3 px-5">
                                    <Link className="py-3" to={'/single-practise-area'}>
                                        Investment Law
                                    </Link>
                                </button>
                            </div>
                            <div className="mr-2">
                                <button className="bg-prottoBG text-white font-montserrat font-bold hover:bg-prottoHover transition-all duration-300 rounded-[5px] mt-5 py-3 px-5">
                                    <Link className="py-3" to={'/single-practise-area'}>
                                        Energy Law
                                    </Link>
                                </button>
                            </div>
                            <div className="mr-2">
                                <button className="bg-prottoBG text-white font-montserrat font-bold hover:bg-prottoHover transition-all duration-300 rounded-[5px] mt-5 py-3 px-5">
                                    <Link className="py-3" to={'/single-practise-area'}>
                                        Labor &amp; Employment
                                    </Link>
                                </button>
                            </div>
                            <div className="mr-2">
                                <button className="bg-prottoBG text-white font-montserrat font-bold hover:bg-prottoHover transition-all duration-300 rounded-[5px] mt-5 py-3 px-5">
                                    <Link className="py-3" to={'/single-practise-area'}>
                                        IT &amp; Data Protection
                                    </Link>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="w-1/2 bg-cover max-lg:bg-contain max-lg:h-[500px] max-lg:w-full bg-no-repeat h-[630px] maykl__bg">
                    </div>
                </div>
            </section>
            <TeamContent />
        </>
    );
}

export default TeamSingle