import Banner from "./components/home/Banner";
import HomeEight from "./components/home/homeEighth";
import HomeFifth from "./components/home/homeFifth";
import HomeFirst from "./components/home/homeFirst";
import HomeSecond from "./components/home/homeSecond";
import HomeSeventh from "./components/home/homeSeventh";
import HomeSixth from "./components/home/homeSixth";
import HomeThird from "./components/home/homeThird";

function Home() {
    return (
        <>
            <Banner />
            <HomeFirst />
            <HomeSecond />
            <HomeThird />
            <HomeFifth />
            <HomeSixth />
            <HomeSeventh />
            <HomeEight />
        </>
    );
}

export default Home