import VID from "../../../assets/images/WEBSITE_VIDEO_NEWEST_VERSION-2-1 (1).mp4"

function Banner() {
    return (
        <>
            <div className="w-full h-full">
                <video id="bannerVid" className="h-full w-full max-xl:object-fill" autoPlay muted playsInline loop>
                    <source src={VID} type="video/mp4"></source>
                </video>
            </div>
        </>
    );
}

export default Banner