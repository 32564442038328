import { Link } from "react-router-dom";


function MegaMenu() {
    return (
        <>
            <div className="p-6 mega-menu mb-16 sm:mb-0 shadow-xl bg-prottoBG">
                <div className="container mx-auto w-full flex flex-wrap justify-between">
                    <div className="w-[49%]">
                        <div className="font-montserrat text-white text-xl">
                            <h2 className="border-b-2 border-white w-[20.5%]">Legal Services</h2>
                        </div>
                        <div className="mt-5 flex justify-between">
                            <ul className="flex flex-col w-[40%]">
                                <li className="mega-menu-link ">
                                    <Link to={'/single-practise-area'}>
                                        Arbitration & Litigation
                                    </Link>
                                </li>
                                <li className="mega-menu-link ">
                                    <Link to={'/single-practise-area'}>
                                        Competition Law
                                    </Link>
                                </li>
                                <li className="mega-menu-link ">
                                    <Link to={'/single-practise-area'}>
                                        Cryptocurrency & Blockchain
                                    </Link>
                                </li>
                                <li className="mega-menu-link ">
                                    <Link to={'/single-practise-area'}>
                                        Immigration Law
                                    </Link>
                                </li>
                                <li className="mega-menu-link ">
                                    <Link to={'/single-practise-area'}>
                                        Investment Law
                                    </Link>
                                </li>
                                <li className="mega-menu-link ">
                                    <Link to={'/single-practise-area'}>
                                        Labor and Employment
                                    </Link>
                                </li>
                                <li className="mega-menu-link ">
                                    <Link to={'/single-practise-area'}>
                                        Public Law and Regulatory
                                    </Link>
                                </li>
                                <li className="mega-menu-link ">
                                    <Link to={'/single-practise-area'}>
                                        Tax Law & Customs
                                    </Link>
                                </li>
                            </ul>
                            <ul className="flex flex-col w-[40%]">
                                <li className="mega-menu-link ">
                                    <Link to={'/single-practise-area'}>
                                        Banking and Finance
                                    </Link>
                                </li>
                                <li className="mega-menu-link ">
                                    <Link to={'/single-practise-area'}>
                                        Corporate/M&A
                                    </Link>
                                </li>
                                <li className="mega-menu-link ">
                                    <Link to={'/single-practise-area'}>
                                        Energy Law
                                    </Link>
                                </li>
                                <li className="mega-menu-link ">
                                    <Link to={'/single-practise-area'}>
                                        Intellectual Property
                                    </Link>
                                </li>
                                <li className="mega-menu-link ">
                                    <Link to={'/single-practise-area'}>
                                        IT & Data Protection
                                    </Link>
                                </li>
                                <li className="mega-menu-link ">
                                    <Link to={'/single-practise-area'}>
                                        Labor and Employment
                                    </Link>
                                </li>
                                <li className="mega-menu-link ">
                                    <Link to={'/single-practise-area'}>
                                        Real Estate
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="w-[49%]">
                        <div className="font-montserrat text-white text-xl">
                            <h2 className="border-b-2 border-white w-[38.5%]">Business Advisory Services</h2>
                        </div>
                        <div className="mt-5 flex justify-between">
                            <ul className="flex flex-col w-[40%]">
                                <li className="mega-menu-link">
                                    <Link to={'/single-practise-area'}>
                                        Accounting & Bookkeeping
                                    </Link>
                                </li>
                                <li className="mega-menu-link">
                                    <Link to={'/single-practise-area'}>
                                        M&A Advising
                                    </Link>
                                </li>
                            </ul>
                            <ul className="flex flex-col w-[40%]">
                                <li className="mega-menu-link ">
                                    <Link to={'/single-practise-area'}>
                                        Corporate Finance Advisory
                                    </Link>
                                </li>
                                <li className="mega-menu-link ">
                                    <Link to={'/single-practise-area'}>
                                        Tax Advisory
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MegaMenu