import { Link } from "react-router-dom";
import NeedUsIMG from "../../../assets/images/asd1-1-1.png"

function HomeSeventh() {
    return (
        <>
            <section className="bg-[#f4f4f4] mt-20 max-lg:py-10">
                <div className="max-w-screen-xl mx-auto flex h-[500px] max-lg:h-auto max-lg:block max-lg:px-10">
                    <div className="w-1/2 flex justify-center items-center max-lg:w-full">
                        <div>
                            <h2 className="flex font-montserrat font-bold text-2xl text-prottoText max-lg:justify-center max-lg:items-center">
                                <img className="mr-2" src={NeedUsIMG} alt="" />
                                WHO NEEDS US
                            </h2>
                            <p className="font-montserrat font-medium mt-3">Every day we work with successful entrepreneurs,
                                skilled executives, and industry leaders, and we can
                                help you too. Our attorneys and business advisers are ready to take efficient, decisive action
                                for
                                our clients in a broad range of matters, and we encourage you to reach out to us to schedule a
                                call
                                to learn more.
                            </p>
                        </div>
                    </div>
                    <div className="relative w-1/2 max-lg:w-full max-lg:static max-lg:mt-5">
                        <div className="max-lg:static max-lg:h-[300px] w-full h-full help__bg bg-no-repeat bg-cover absolute flex justify-center items-center -right-40">
                            <div>
                                <h2 className="font-montserrat font-semibold text-xl text-prottoBG">HOW CAN WE HELP?</h2>
                                <button className="bg-prottoBG text-white font-montserrat font-bold hover:bg-prottoHover transition-all duration-300 rounded-[5px] mt-5 py-3 px-10">
                                    <Link to={'https://calendly.com/appointmentprotto/30min?month=2023-02'} target={'_blank'} className="py-3 px-4">
                                        Schedule a Call
                                    </Link>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-linkColor h-20 w-full" />
            </section>
        </>
    );
}

export default HomeSeventh