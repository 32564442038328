import $ from "jquery";

$(document).ready(function () {
    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            $('#scroll').fadeIn();
        } else {
            $('#scroll').fadeOut();
        }
    });
});

$(document).ready(function () {
    $(window).scroll(function () {
        if ($(this).scrollTop() > 10) {
            $('#mastHeader').removeClass("fixed");
            $('#header__section').removeClass("bg-transparent");
            $('#mastHeader').addClass("static");
            $('#header__section').addClass("bg-prottoBG");
        } else if ($(this).scrollTop() < 0.1) {
            $('#mastHeader').addClass("fixed");
            $('#header__section').addClass("bg-transparent");
            $('#header__section').removeClass("bg-prottoBG");
        }
    });
});

$(document).ready(function () {
    if (parseInt($(window).width()) > 1024) {
        $(window).scroll(function () {
            $('.fadeinleft').each(function (i) {

                var bottom_of_element = $(this).offset().top + $(this).outerHeight();
                var bottom_of_window = $(window).scrollTop() + $(window).height();

                if (bottom_of_window > bottom_of_element) {
                    $(this).animate({ 'opacity': '1', 'margin-left': '0px' }, 1000);
                }

            });
        });
    }
});

$(document).ready(function () {
    if (parseInt($(window).width()) > 1024) {
        $(window).scroll(function () {
            $('.fadeinRight').each(function (i) {

                var bottom_of_element = $(this).offset().top + $(this).outerHeight();
                var bottom_of_window = $(window).scrollTop() + $(window).height();

                if (bottom_of_window > bottom_of_element) {
                    $(this).animate({ 'opacity': '1', 'margin-right': '0px' }, 1000);
                }

            });
        });
    }
});

function makeTimer() {

    //		var endTime = new Date("29 April 2018 9:56:00 GMT+01:00");	
    var endTime = new Date("1 September 2023 9:56:00 GMT+01:00");
    endTime = (Date.parse(endTime) / 1000);

    var now = new Date();
    now = (Date.parse(now) / 1000);

    var timeLeft = endTime - now;

    var days = Math.floor(timeLeft / 86400);
    var hours = Math.floor((timeLeft - (days * 86400)) / 3600);
    var minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600)) / 60);
    var seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));

    if (hours < "10") { hours = "0" + hours; }
    if (minutes < "10") { minutes = "0" + minutes; }
    if (seconds < "10") { seconds = "0" + seconds; }

    $("#days").html(days);
    $("#hours").html(hours);
    $("#minutes").html(minutes);
    $("#seconds").html(seconds);

}

setInterval(function () { makeTimer(); }, 1000);