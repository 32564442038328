import { Link } from "react-scroll";

function ScrollToTop() {
    return (
        <>
            <Link id="scroll" activeClass='ll' to={'mastHeader'} spy={true} smooth={true} duration={700} className="border-2 border-black text-white text-2xl font-montserrat cursor-pointer">
                <span className="flex justify-center items-center mt-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" className="w-8 h-8">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75L12 3m0 0l3.75 3.75M12 3v18" />
                    </svg>  
                </span>
            </Link>
        </>
    );
}

export default ScrollToTop