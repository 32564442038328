import React from 'react'
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/Capture1-removebg-preview-300x98-1-e1657690799907 (1).png'
import MegaMenu from '../components/header/megaMenu';

function Header() {
    const [open, setOpen] = React.useState(false);
    return (
        <>
            <header className="fixed w-full z-[99]" id="mastHeader">
                <div className="xl:p-5 z-[99] container-fluid fixed max-lg:p-5 top-0 w-full flex justify-center transition-all duration-300 ease-linear bg-transparent" id='header__section'>
                    <div className="container">
                        <div className="flex justify-between items-center py-2 md:justify-start md:space-x-10">
                            <div className="flex justify-start lg:w-0 lg:flex-1 ">
                                <Link to={'/'}>
                                    <img src={Logo} alt='logo' className='w-full h-16' />
                                </Link>
                            </div>
                            <div className="md:hidden ">
                                <button
                                    type="button"
                                    className="rounded-md   inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                    onClick={() => setOpen(!open)}
                                >
                                    <svg
                                        className="h-6 w-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="white"
                                        aria-hidden="true"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M4 6h16M4 12h16M4 18h16"
                                        />
                                    </svg>
                                </button>
                            </div>
                            <nav className="hidden md:flex space-x-10 z-[99]">
                                <ul className='flex flex-col duration-300 ease-linear sm:transition-none mt-0 mx-4 xl:flex-row xl:items-center xl:mx-0 xl:ml-auto xl:mt-0 xl:pt-0 xl:border-0 xl:space-x-6'>
                                    <li className='header-link'>
                                        <Link to={'/digital'} >
                                            Digital Services
                                        </Link>
                                    </li>
                                    <li className='header-link hoverable'>
                                        <Link className='megaMenuA' to={'/practise-area'}>
                                            Practise Area
                                        </Link>
                                        <MegaMenu />
                                    </li>
                                    <li className='header-link'>
                                        <Link to={'/our-team'} >
                                            Our Team
                                        </Link>
                                    </li>
                                    <li className='header-link'>
                                        <Link to={'/about'} >
                                            About Us
                                        </Link>
                                    </li>
                                    <li className="header-link">
                                        <div>
                                            <button className="peer insafter">Insights</button>
                                            <div className="hidden peer-hover:flex hover:flex
                                     w-[200px]
                                     flex-col bg-prottoBG drop-shadow-lg absolute">
                                                <Link to={'/blog'} className="px-5 py-3 hover:bg-gray-200 hover:text-linkColor text-gray-200">
                                                    Publications
                                                </Link>
                                                <span className="w-full border-b border-gray-200"></span>
                                                <Link to={'/blog'} className="px-5 py-3 hover:bg-gray-200 hover:text-linkColor text-gray-200">
                                                    Legal Updates
                                                </Link>
                                                <span className="w-full border-b border-gray-200"></span>
                                                <Link to={'#'} className="px-5 py-3 hover:bg-gray-200 hover:text-linkColor text-gray-200">
                                                    News
                                                </Link>
                                            </div>
                                        </div>
                                    </li>
                                    <li className='header-link'>
                                        <Link to={'/contact'} >
                                            Contact Us
                                        </Link>
                                    </li>
                                    <li className="schedule__btn">
                                        <Link to={'https://calendly.com/appointmentprotto/30min?month=2023-02'} target={'_blank'}>
                                            Schedule a Call
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div
                        className={
                            open
                                ? "opacity-100 scale-100 transition ease-out duration-200 absolute top-0 inset-x-0 p-2  transform origin-top-right md:hidden"
                                : "opacity-0 scale-95 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                        }
                    >
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-y-2 bg-prottoBG p-5 divide-gray-50">
                            <div className="">
                                <div className="flex items-center justify-between">
                                    <div>
                                        <Link to={'/'}>
                                            <img src={Logo} alt='logo' className='w-full h-16' />
                                        </Link>
                                    </div>
                                    <div className="">
                                        <button
                                            type="button"
                                            className="rounded-md p-2 inline-flex items-center justify-center text-white"
                                            onClick={() => setOpen(!open)}
                                        >
                                            <span className="sr-only">Close menu</span>
                                            <svg
                                                className="h-6 w-6"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                    d="M6 18L18 6M6 6l12 12"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="py-6 px-5 space-y-6">
                                <div className="grid grid-cols-1 gap-y-4 gap-x-8">
                                    <ul className='flex flex-col duration-300 ease-linear sm:transition-none mt-0 mx-4 xl:flex-row xl:items-center xl:mx-0 xl:ml-auto xl:mt-0 xl:pt-0 xl:border-0 xl:space-x-6'>
                                        <li className='header-link'>
                                            <Link to={'/digital'} >
                                                Digital Services
                                            </Link>
                                        </li>
                                        <li className=' header-link hoverable'>
                                            <Link to={'/practise-area'}>
                                                Practise Area
                                            </Link>
                                            <MegaMenu />
                                        </li>
                                        <li className='header-link'>
                                            <Link to={'/our-team'} >
                                                Our Team
                                            </Link>
                                        </li>
                                        <li className='header-link'>
                                            <Link to={'/about'} >
                                                About Us
                                            </Link>
                                        </li>
                                        <li className="header-link max-lg:hidden">
                                            <div>
                                                <button className="peer insafter">Insights</button>
                                                <div className="hidden peer-hover:flex hover:flex
                                     w-[200px]
                                     flex-col bg-prottoBG drop-shadow-lg absolute">
                                                    <Link to={'/blog'} className="px-5 py-3 hover:bg-gray-200 hover:text-linkColor text-gray-200">
                                                        Publications
                                                    </Link>
                                                    <span className="w-full border-b border-gray-200"></span>
                                                    <Link to={'/blog'} className="px-5 py-3 hover:bg-gray-200 hover:text-linkColor text-gray-200">
                                                        Legal Updates
                                                    </Link>
                                                    <span className="w-full border-b border-gray-200"></span>
                                                    <Link to={'#'} className="px-5 py-3 hover:bg-gray-200 hover:text-linkColor text-gray-200">
                                                        News
                                                    </Link>
                                                </div>
                                            </div>
                                        </li>
                                        <li className='header-link'>
                                            <Link to={'/contact'} >
                                                Contact Us
                                            </Link>
                                        </li>
                                        <li className="schedule__btn">
                                            <Link to={'https://calendly.com/appointmentprotto/30min?month=2023-02'} target={'_blank'}>
                                                Schedule a Call
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

        </>
    );
}

export default Header