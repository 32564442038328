import { Link } from "react-router-dom";
import NeedUsIMG from "../../../assets/images/asd1-1-1.png"
import AccordionIMG1 from "../../../assets/images/arrows.svg"
import AccordionIMG2 from "../../../assets/images/lightbulb.svg"
import AccordionIMG3 from "../../../assets/images/arrow-target.svg"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import BrandSliderIMG1 from "../../../assets/images/partners/viber_image_2022-11-03_16-24-32-780-4.png"
import BrandSliderIMG2 from "../../../assets/images/partners/1.jpg"
import BrandSliderIMG3 from "../../../assets/images/partners/10.png"
import BrandSliderIMG4 from "../../../assets/images/partners/2.png"
import BrandSliderIMG5 from "../../../assets/images/partners/3.png"
import BrandSliderIMG6 from "../../../assets/images/partners/4.png"
import BrandSliderIMG7 from "../../../assets/images/partners/5.png"
import BrandSliderIMG8 from "../../../assets/images/partners/6.png"
import BrandSliderIMG9 from "../../../assets/images/partners/7.jpg"
import BrandSliderIMG10 from "../../../assets/images/partners/8.png"
import BrandSliderIMG11 from "../../../assets/images/partners/9.png"
import BrandSliderIMG12 from "../../../assets/images/partners/image-000-removebg-preview.png"


function HomeFirst() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
    };
    return (
        <>
            <section className="max-w-screen-xl mx-auto">
                <div className="flex justify-between w-full h-full space-x-4 mt-10 max-lg:block max-lg:space-x-0 max-lg:px-10">
                    <div className="w-1/3 h-auto max-lg:w-full max-lg:mb-2">
                        <Link className="protto__link" to={'/individuals'}>
                            What we do for
                            <span className="protto__span">Individuals
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                    stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                </svg>
                            </span>
                        </Link>
                    </div>
                    <div className="w-1/3 h-auto max-lg:w-full max-lg:mb-2">
                        <Link className="protto__link" to={'/business'}>
                            What we do for
                            <span className="protto__span">Bussinesses
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                    stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                </svg>
                            </span>
                        </Link>
                    </div>
                    <div className="w-1/3 h-auto max-lg:w-full max-lg:mb-2">
                        <Link className="protto__link" to={'https://calendly.com/appointmentprotto/30min?month=2023-02'} target={'_blank'}>
                            Schedule a
                            <span className="protto__span">Call
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                    stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                </svg>
                            </span>
                        </Link>
                    </div>
                </div>
                <div className="flex justify-between mt-10 max-lg:block">
                    <div className="w-1/3 max-lg:w-full max-lg:px-10">
                        <h2 className="flex font-montserrat font-bold text-xl max-lg:text-lg text-prottoText">
                            <img className="mr-2" src={NeedUsIMG} alt=""></img>
                            WHO NEEDS US
                        </h2>
                        <h2 className="text-3xl mt-3 font-montserrat font-bold text-prottoText">
                            OUR GOAL IS TO EXCEED YOUR EXPECTATIONS
                        </h2>
                        <p className="font-montserrat font-medium mt-2">

                            We take pride in providing our clients with individual attention and rigorous advocacy. From setting
                            up legal entities to complex cases, we have established a reputation for providing quality legal
                            services. We recognize that the cost of legal representation or business advisory can be a
                            significant investment. With that in mind, we offer a free initial consultation in office or
                            remotely.
                            <br></br>
                            <span className="font-bold">Reach out to us now!</span>
                        </p>
                        <button className="bg-prottoBG text-white font-montserrat font-bold hover:bg-prottoHover transition-all duration-300 rounded-[5px] mt-5 py-3 px-10 max-lg:px-5">
                            <Link className="py-3 px-10" to={'https://calendly.com/appointmentprotto/30min?month=2023-02'} target={'_blank'}>SCHEDULE A CALL</Link>
                        </button>
                    </div>
                    <div className="w-1/2 max-lg:w-full">
                        <div className="accordion flex flex-col items-center space-y-3 mt-16  pr-0 max-xl:p-4 md:pr-10 max-lg:px-10">
                            <div className="">
                                <input type="checkbox" name="panel" id="panel-1" className="hidden" />
                                <label htmlFor="panel-1" className="flex relative space-x-3 bg-linkColor text-[14px] sm:text-[17px] p-4 height-[60px] Label__BTN text-prottoText font-montserrat font-bold text-xl max-lg:text-lg max-lg:p-2">
                                    <img className="max-lg:hidden" src={AccordionIMG1} alt=""></img>
                                    <span>We provide top-notch professional assistance</span>
                                </label>
                                <div className="accordion__content overflow-hidden bg-[white] text-black text-[13px]">
                                    <p className="accordion__body leading-6 font-normal p-4 text-justify" id="panel1">
                                        We offer our clients top-notch and value-added professional assistance, combined with an
                                        in-depth understanding of the particular areas of the business. Our focus is on
                                        delivering highly skilled, effective and innovative legal representation to businesses
                                        of all sizes in a timely manner and at a reasonable cost. Our goal is to exceed the
                                        expectations of every client.
                                    </p>
                                </div>
                            </div>
                            <div className="">
                                <input type="checkbox" name="panel" id="panel-2" className="hidden" />
                                <label htmlFor="panel-2" className="relative flex space-x-3 bg-linkColor font-pop text-[14px] sm:text-[17px] p-4 height-[60px] Label__BTN text-prottoText font-montserrat font-bold text-xl max-lg:text-lg max-lg:p-2">
                                    <img className="max-lg:hidden" src={AccordionIMG2} alt=""></img>
                                    <span>We are honest, precise and innovative</span>
                                </label>
                                <div className="accordion__content overflow-hidden bg-[white] font-pop text-black text-[13px]">
                                    <p className="accordion__body leading-6 font-normal p-4 text-justify" id="panel2">
                                        We carefully guide our clients through the legal process. We are truthful in setting
                                        expectations and we honor our commitments. We discuss a range of potential solutions
                                        and, once a gameplan is set in place, we go after it with focus and dedication with the
                                        aim of protecting our clients every step of the way. We represent individuals and
                                        businesses in a diverse scope of legal and business advisory related matters.
                                    </p>
                                </div>
                            </div>
                            <div className="">
                                <input type="checkbox" name="panel" id="panel-3" className="hidden" />
                                <label htmlFor="panel-3" className="relative flex space-x-3 bg-linkColor font-pop text-[14px] sm:text-[17px] p-4 height-[60px] Label__BTN text-prottoText font-montserrat font-bold text-xl max-lg:text-lg  max-lg:p-2">
                                    <img className="max-lg:hidden" src={AccordionIMG3} alt=""></img>
                                    <span>We communicate and manage expectations</span>
                                </label>
                                <div className="accordion__content overflow-hidden bg-[white] font-pop text-black text-[13px]">
                                    <p className="accordion__body leading-6 font-normal p-4 text-justify" id="panel3">
                                        Communication is the foundation of every well-functioning business relationship.In order
                                        to ensure that everyone is on the same page, from the initial client intake meeting to
                                        the close of the file, we strive to engage in frank discussions with our clients
                                        relating to anticipated costs, estimated turnaround times of deliverables, the
                                        likelihood of success of a particular matter, and what to expect from us throughout the
                                        process.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Slider {...settings} className={'my-20 max-lg:px-10'}>
                    <div className="h-[200px] w-full flex items-center">
                        <Link to={'https://abovacademy.com/'} target="_blank">
                            <img className="h-full w-full object-contain" src={BrandSliderIMG1} alt="brand1"></img>
                        </Link>
                    </div>
                    <div className="h-[200px] w-full flex items-center">
                        <Link to={'https://cdf.am/'} target="_blank">
                            <img className="h-full w-full object-contain" src={BrandSliderIMG2} alt="brand2"></img>
                        </Link>
                    </div>
                    <div className="h-[200px] w-full flex items-center">
                        <Link to={'https://getrentacar.com/'} target="_blank">
                            <img className="h-full w-full object-contain" src={BrandSliderIMG3} alt="brand3"></img>
                        </Link>
                    </div>
                    <div className="h-[200px] w-full flex items-center">
                        <Link to={'https://beegraphy.com/'} target="_blank">
                            <img className="h-full w-full object-contain" src={BrandSliderIMG4} alt="brand4"></img>
                        </Link>
                    </div>
                    <div className="h-[200px] w-full flex items-center">
                        <Link to={'https://kaass.com/'} target="_blank">
                            <img className="h-full w-full object-contain" src={BrandSliderIMG5} alt="brand5"></img>
                        </Link>
                    </div>
                    <div className="h-[200px] w-full flex items-center">
                        <Link to={'https://above.academy/'} target="_blank">
                            <img className="h-full w-full object-contain" src={BrandSliderIMG6} alt="brand6"></img>
                        </Link>
                    </div>
                    <div className="h-[200px] w-full flex items-center">
                        <Link to={'https://www.usaid.gov/armenia'} target="_blank">
                            <img className="h-full w-full object-contain" src={BrandSliderIMG7} alt="brand7"></img>
                        </Link>
                    </div>
                    <div className="h-[200px] w-full flex items-center">
                        <Link to={'https://www.cftjustice.org/'} target="_blank">
                            <img className="h-full w-full object-contain" src={BrandSliderIMG8} alt="brand8"></img>
                        </Link>
                    </div>
                    <div className="h-[200px] w-full flex items-center">
                        <Link to={'https://hynest.net/'} target="_blank">
                            <img className="h-full w-full object-contain" src={BrandSliderIMG9} alt="brand9"></img>
                        </Link>
                    </div>
                    <div className="h-[200px] w-full flex items-center">
                        <Link to={'https://gatewayarmenia.org/'} target="_blank">
                            <img className="h-full w-full object-contain" src={BrandSliderIMG10} alt="brand10"></img>
                        </Link>
                    </div>
                    <div className="h-[200px] w-full flex items-center">
                        <Link to={'https://jetruby.com/'} target="_blank">
                            <img className="h-full w-full object-contain" src={BrandSliderIMG11} alt="brand11"></img>
                        </Link>
                    </div>
                    <div className="h-[200px] w-full flex items-center">
                        <Link to={'http://uate.com/'} target="_blank">
                            <img className="h-full w-full object-contain" src={BrandSliderIMG12} alt="brand12"></img>
                        </Link>
                    </div>
                </Slider>
            </section>
        </>
    );
}

export default HomeFirst