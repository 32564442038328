import { Link } from "react-router-dom";
import PhilosophyIMG from "../../../assets/images/11.jpg"
import LegalIMG from "../../../assets/images/photo1658731698.jpeg"
import ComunityIMG from "../../../assets/images/Capturea.png"
import DigitalIMG from "../../../assets/images/photo1658743352.jpeg"

function HomeSecond() {
    return (
        <>
            <section className="bg-[#F4F4F4] py-10">
                <main className="max-w-screen-xl mx-auto">
                    <div>
                        <h2 className="flex font-montserrat font-bold text-xl text-prottoText max-lg:items-center max-lg:justify-center">
                            <img className="mr-2" src="./public/images/asd1-1-1.png" alt="" />
                            WHY CHOOSE PROTTO?
                        </h2>
                    </div>
                    <div className="max-lg:items-center max-lg:flex max-lg:justify-center">
                        <h2 className="flex font-montserrat font-bold text-5xl mt-5 text-prottoText max-lg:text-lg">
                            OUR PHILOSOPHY
                        </h2>
                    </div>
                    <div className="bg-white w-full flex mt-10 max-lg:block">
                        <div className="w-1/2 relative max-lg:static max-lg:w-full reveal">
                            <img className="fadeinleft max-lg:ml-0 max-lg:opacity-[1] opacity-0 ml-[-300px] max-w-full max-lg:static absolute h-full -left-[152px] top-20" src={PhilosophyIMG} alt="" />
                        </div>
                        <div className="w-1/2 p-10 max-lg:w-full">
                            <h2 className="font-montserrat text-2xl font-bold max-lg:text-center">CLIENTS FIRST</h2>
                            <div className="border-b-4 border-linkColor w-[10%] mt-3 max-lg:mx-auto" />
                            <p className="mt-5 font-montserrat">
                                Putting our clients first is a simple philosophy, but it is one that flows throughout every part
                                of our business and underpins everything we do. We engage in our work by adopting the
                                client-focused mindset that we are in partnership with you. We are on the same team, working
                                towards the same goals of achieving the most desirable outcome possible for you. We think of
                                ourselves as more than just a law firm, but rather, your personal and business legal partners.
                                <br />
                                <br />
                                Whether legal advice, tax planning advice, corporate structuring advice, or just the formation
                                of an Armenian company, these are services that we are able to offer competently and directly.
                                By placing all legal and related services under one umbrella we are also able to offer a better
                                financial outcome for our clients.
                            </p>
                        </div>
                    </div>
                    <div className="bg-white w-full flex mt-40 max-lg:flex-col-reverse max-lg:mt-20">
                        <div className="w-1/2 p-10 max-lg:w-full">
                            <h2 className="font-montserrat text-2xl font-bold max-lg:text-center">LEGAL MEETS BUSINESS</h2>
                            <div className="border-b-4 border-linkColor w-[10%] mt-3 max-lg:mx-auto" />
                            <p className="mt-5 font-montserrat">
                                At Protto, lawyers, accountants and business consultants work together seamlessly, co-operating
                                in such a way that the client doesn’t have to get involved in unnecessary complexities. We
                                specifically provide a joined-up legal and accounting service which makes matters much simpler
                                and easier for our clients. This is particularly important given the world is becoming
                                increasingly difficult to understand and navigate.
                            </p>
                            <button className="bg-prottoBG text-white font-montserrat font-bold hover:bg-prottoHover transition-all duration-300 rounded-[5px] mt-5 py-3 px-10 max-lg:px-2">
                                <Link to={"/practise-area"} className="py-3 px-10 max-lg:px-0">
                                    VIEW ALL PRACTICE AREAS
                                </Link>
                            </button>
                        </div>
                        <div className="w-1/2 relative max-lg:static max-lg:w-full revealright max-lg:order-1">
                            <img className="fadeinRight max-lg:opacity-[1] max-lg:mr-0 opacity-0 mr-[-300px] max-w-full absolute max-lg:static h-full -right-[150px] bottom-20" src={LegalIMG} alt="" />
                        </div>
                    </div>
                    <div className="bg-white w-full flex mt-40 max-lg:block max-lg:mt-20">
                        <div className="w-1/2 relative max-lg:static max-lg:w-full reveal">
                            <img className="fadeinleft max-lg:opacity-[1] max-lg:ml-0 opacity-0 ml-[-300px] max-w-full max-lg:static absolute h-full -left-[152px] bottom-20" src={ComunityIMG} alt="" />
                        </div>
                        <div className="w-1/2 p-10 max-lg:w-full">
                            <h2 className="font-montserrat text-2xl font-bold max-lg:text-center">COMMUNITY INVOLVEMENT</h2>
                            <div className="border-b-4 border-linkColor w-[10%] mt-3 max-lg:mx-auto" />
                            <p className="mt-5 font-montserrat">
                                We consider it our corporate social responsibility to enhance our community involvement and
                                commitment to assisting people and communities in need across Armenia. People at Protto value
                                the opportunity for positive community engagement and have a voice in the causes they champion.
                                We support number of charities and community-based organizations that align with our firm’s core
                                values, interests and community priorities. We are particularly committed to initiatives that
                                focus on supporting charities in the children health-care and educational spaces as well as
                                organizations working with disadvantaged groups.
                            </p>
                            <button className="bg-prottoBG text-white font-montserrat font-bold hover:bg-prottoHover transition-all duration-300 rounded-[5px] mt-5 py-3 px-10">
                                <Link to={'/our-team'} className="py-3 px-10">
                                    OUR TEAM
                                </Link>
                            </button>
                        </div>
                    </div>
                    <div className="bg-white w-full flex mt-40 max-lg:flex-col-reverse max-lg:mt-20">
                        <div className="w-1/2 p-10 max-lg:w-full">
                            <h2 className="font-montserrat text-2xl font-bold max-lg:text-center">SOLUTIONS &amp; STRATEGIES FOR
                                TOMORROW</h2>
                            <div className="border-b-4 border-linkColor w-[10%] mt-3 max-lg:mx-auto" />
                            <p className="mt-5 font-montserrat">
                                The digital service revolution is gradually taking over the legal practice. At Protto, we want
                                to change things by making legal services affordable, simpler and accessible to more people than
                                ever before. From registering your company's trademark to forming your own legal entity - with
                                Protto you can do it all <span className="font-bold">fully remotely</span>. More information on our
                                digital legal services awill be
                                available soon!
                            </p>
                            <button className="bg-prottoBG text-white font-montserrat font-bold hover:bg-prottoHover transition-all duration-300 rounded-[5px] mt-5 py-3 px-10">
                                <Link to={'/digital'} className="py-3 px-10">
                                    Digital Services
                                </Link>
                            </button>
                        </div>
                        <div className="w-1/2 relative max-lg:static max-lg:w-full revealright">
                            <img className="fadeinRight max-lg:opacity-[1] opacity-0 mr-[-300px] max-lg:mr-0 max-w-full absolute h-full -right-[150px] bottom-20 max-lg:static" src={DigitalIMG} alt="" />
                        </div>
                    </div>
                </main>
            </section>
        </>
    );
}

export default HomeSecond