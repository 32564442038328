import { Link } from 'react-router-dom';
import MeetIMG from '../assets/images/asd1-1-1.png'
import InovatorsIMG from '../assets/images/photo1658741478.jpeg'

function About() {
    return (
        <>
            <section className="about__bg bg-cover bg-[center] h-[500px] w-full flex justify-center items-center">
                <h2 className="font-montserrat font-semibold text-6xl text-white">About Us</h2>
            </section>
            <section className="max-w-screen-xl mx-auto">
                <div className="flex justify-between w-full h-full space-x-4 mt-10 max-lg:block max-lg:space-x-0">
                    <div className="w-1/2 h-auto max-lg:w-full max-lg:mb-2">
                        <Link className="protto__link" to={'/individuals'}>
                            What we do for
                            <span className="protto__span">Individuals
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                </svg>
                            </span>
                        </Link>
                    </div>
                    <div className="w-1/2 h-auto max-lg:w-full max-lg:mb-2">
                        <Link className="protto__link" to={'/business'}>
                            What we do for
                            <span className="protto__span">Bussinesses
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                </svg>
                            </span>
                        </Link>
                    </div>
                </div>
                <div className="w-full flex justify-center items-center mt-5 max-lg:mt-0">
                    <div className="w-1/2 h-auto max-lg:w-full max-lg:mb-2">
                        <Link className="protto__link" to={'/clients'}>
                            What our
                            <span className="protto__span">Clients Say
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                </svg>
                            </span>
                        </Link>
                    </div>
                </div>
                <div className="flex space-x-7 mt-20 h-auto max-lg:flex-col-reverse max-lg:px-10 max-lg:space-x-0">
                    <div className="w-1/2 text-prottoText font-montserrat font-medium max-lg:w-full max-lg:mt-5">
                        <p>
                            Protto offers its clients top-notch legal and business advisory services while maintaining an
                            affordable pricing policy for any type and size of business. The services we offer are comprehensive
                            for businesses, and they include (but are not limited to): - risk assessment and legal assistance to
                            companies who want to make investments or have already started their activities in Armenia. - Tax
                            planning, corporate structural consulting, establishment of organizations in Armenia. We have the
                            courage to reach beyond our comfort zone to immerse ourselves in the clients’ cases and provide
                            utterly practical and conventional solutions. Moreover, Our Digital Services will enable people to
                            take the first steps and every step after making their ideas/businesses official as seamless as
                            possible. <span className="text-linkColor font-bold">WE ARE INNOVATORS AT LAW</span> and our mission is
                            to revolutionize the way legal aligns with the
                            business.
                            <br />
                            <br />
                            We are trusted partners, allies searching for the most appropriate legal and business solutions to
                            each client’s needs. We create lasting relationships based on a culture of commitment and
                            collaboration, the development of highly competent teams and the permanent updating of our legal and
                            business knowledge. We want our customers to recognize us for their ability to challenge
                            conventional thinking in search of truly innovative answers to unique problems. Our purpose is to
                            provide legal intelligence that leverages opportunities and helps preserve and create value for your
                            business. We are innovators at law and we want to revolutionize the way legal aligns with business.
                        </p>
                    </div>
                    <div className="w-1/2 max-lg:w-full">
                        <img className="w-full h-full" src={InovatorsIMG} alt="" />
                    </div>
                </div>
            </section>
            <section className="relative max-lg:static">
                <main className="max-lg:before:hidden help__bg helpBG__before bg-no-repeat bg-cover bg-[center] max-lg:h-auto max-lg:p-10 w-full h-[575px] mt-20 flex justify-center items-center ">
                    <div className="w-1/2 text-center max-lg:w-full">
                        <h2 className="flex justify-center items-center font-montserrat font-bold text-xl max-lg:text-lg text-prottoText">
                            <img className="mr-2" src={MeetIMG} alt="" />
                            LET’S MEET.
                        </h2>
                        <h2 className="font-montserrat font-bold text-4xl text-prottoText mt-2">
                            SPEAK WITH OUR EXPERT NOW.
                        </h2>
                        <p className="font-montserrat font-medium mt-8">
                            For more information about what we do for individuals and businesses, please contact
                            us to arrange an initial consultation. To speak with one of our experts,
                            call +(374) 60730740 or schedule a call online today.
                        </p>
                    </div>
                </main>
                <div className="bg-linkColor h-20 w-full" />
            </section>
        </>
    );
}

export default About