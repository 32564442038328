import { Route, Routes } from 'react-router-dom';
import About from './pages/about';
import Digital from './pages/digital';
import Home from './pages/home';
import Team from './pages/Team';
import SinglePost from './pages/singlePost';
import Contact from './pages/contact';
import TeamSingle from './pages/teamSingle';
import Footer from './pages/layouts/footer';
import Header from './pages/layouts/header';
import PractiseAreaArchive from './pages/practiseAreaArchive';
import PractiseAreaSingle from './pages/practiseAreaSingle';
import Individuals from './pages/individuals';
import Clients from './pages/clients';
import Business from './pages/business';
import Blog from './pages/blog';
import "./assets/js/script"

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/digital' element={<Digital />} />
        <Route path='/practise-area' element={<PractiseAreaArchive />} />
        <Route path='/our-team' element={<Team />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/single-post' element={<SinglePost />} />
        <Route path='/single-team' element={<TeamSingle />} />
        <Route path='/single-practise-area' element={<PractiseAreaSingle />} />
        <Route path='/individuals' element={<Individuals/>} />
        <Route path='/clients' element={<Clients/>}/>
        <Route path='/business' element={<Business/>}/>
        <Route path='/blog' element={<Blog/>}/>
      </Routes>
      <Footer />
    </>
  );
}

export default App;
