import { Link } from "react-router-dom";
import WhatIMG from '../assets/images/asd1-1-1.png'
import AccordionIMG1 from '../assets/images/Group-242.svg'
import AccordionIMG2and3 from '../assets/images/g1240.svg'
import AccordionIMG4 from '../assets/images/lightbulb.svg'
import AccordionIMG5 from '../assets/images/Group-247.svg'


function Individuals() {
    return (
        <>
            <section className="individuals__bg bg-cover bg-[center] h-[500px] w-full flex justify-center items-center">
                <h2 className="font-montserrat font-extralight text-6xl text-white max-lg:text-2xl">WHAT WE DO FOR <span className="border-linkColor border-b-8 max-lg:border-b-4">INDIVIDUALS</span></h2>
            </section>
            <section className="max-w-screen-xl mx-auto mt-20 max-lg:px-10">
                <div className="flex space-x-5 max-lg:block max-lg:space-x-0">
                    <div className="w-2/3 max-lg:w-full font-montserrat font-medium">
                        <p>
                            When individuals need legal assistance? Either when they face legal problems such as human rights
                            violations, wrongful employment termination, defamation, unlawful administration, inheritance red
                            tape, transactional issues, litigation etc. or when they need professional advice and support with
                            respect to their initiatives involving legal component whether investments, charities or early
                            stages of business set-up.
                            <br />
                            <br />
                            Protto legal brings together extensive set of legal and correlated services meant to cover every and
                            each aspect of your needs. By choosing us individuals may expect committed representation and
                            protection of their interests through and through.
                        </p>
                    </div>
                    <div className="w-1/3 max-lg:w-full max-lg:mt-5">
                        <div className="w-full h-full">
                            <div className="w-full mb-2">
                                <Link className="protto__link" to={'/individuals'}>
                                    What we do for
                                    <span className="protto__span">Individuals
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                        </svg>
                                    </span>
                                </Link>
                            </div>
                            <div className="w-full mb-2">
                                <Link className="protto__link" to={'/business'}>
                                    What we do for
                                    <span className="protto__span">Bussinesses
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                        </svg>
                                    </span>
                                </Link>
                            </div>
                            <div className="w-full max-lg:mb-2">
                                <Link className="protto__link" to={'/clients'}>
                                    What Our
                                    <span className="protto__span">Clients Say
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                        </svg>
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-10">
                    <h2 className="flex font-montserrat font-medium text-xl max-lg:text-lg">
                        <img className="mr-2" src={WhatIMG} alt="" />
                        WHAT WE DO
                    </h2>
                    <p className="font-montserrat mt-5 font-medium">
                        Our values in struggling for justice and dedication to human rights drive us provide pro bono
                        appointments for people who are not in a position to afford legal services.
                        <br />
                        <br />
                        Our practitioners are passionate about using their deep knowledge and vast experience to help you in
                        your times of need and resolve any issues impeding your path of success.
                    </p>
                </div>
                <div className="mt-10">
                    <h4 className="font-montserrat font-bold text-lg">So, what can we do for you?</h4>
                    <div className="w-full">
                        <div className="accordion flex flex-col items-center space-y-3 mt-5 pr-0 max-xl:p-4 md:pr-10">
                            <div className="">
                                <input type="checkbox" name="panel" id="panel-1" className="hidden" />
                                <label htmlFor="panel-1" className="flex relative space-x-3 bg-linkColor font-pop text-[14px] sm:text-[17px] p-4 height-[60px] Label__BTN text-prottoText font-montserrat font-bold text-xl max-lg:text-lg">
                                    <img className="max-lg:hidden" src={AccordionIMG1} alt=""></img>
                                    <span>Legal advice & Consultation</span>
                                </label>
                                <div className="accordion__content overflow-hidden bg-[white] font-pop text-black text-[13px]">
                                    <p className="accordion__body leading-6 font-normal p-4 text-justify" id="panel1">
                                        We are giving professional legal feedback and opinion as to how the statutes and case
                                        law apply in a specific case. First, we try to figure out your issue in the legal
                                        context, we dig the issue much into details to have a clear-cut overview, then we step
                                        into elaborating legal opinion with insightful analyses and justified recommendations.
                                        Such opinions are expressed either in written or verbally communicated to the client.
                                        Due to our legal advice and consulting, people are directed wisely, lawfully and
                                        expediently.
                                    </p>
                                </div>
                            </div>
                            <div className="">
                                <input type="checkbox" name="panel" id="panel-2" className="hidden" />
                                <label htmlFor="panel-2" className="relative flex space-x-3 bg-linkColor font-pop text-[14px] sm:text-[17px] p-4 height-[60px] Label__BTN text-prottoText font-montserrat font-bold text-xl max-lg:text-lg">
                                    <img className="max-lg:hidden" src={AccordionIMG2and3} alt=""></img>
                                    <span>Challenging transactions and deeds</span>
                                </label>
                                <div className="accordion__content overflow-hidden bg-[white] font-pop text-black text-[13px]">
                                    <p className="accordion__body leading-6 font-normal p-4 text-justify" id="panel2">
                                        We may challenge and in whole or in part quash transactions whereby your rights have
                                        been violated or an unlawful obligation have been assigned to you.

                                        People dealing with administrative authorities may face unfair and unjust treatment. As
                                        such, government agencies do not issue requested petition; individuals are fined or
                                        illegal administration in manifested. We can protect your rights in those and similar
                                        legal processes to achieve the best results in relation to state bodies.
                                    </p>
                                </div>
                            </div>
                            <div className="">
                                <input type="checkbox" name="panel" id="panel-3" className="hidden" />
                                <label htmlFor="panel-3" className="relative flex space-x-3 bg-linkColor font-pop text-[14px] sm:text-[17px] p-4 height-[60px] Label__BTN text-prottoText font-montserrat font-bold text-xl max-lg:text-lg">
                                    <img className="max-lg:hidden" src={AccordionIMG2and3} alt=""></img>
                                    <span>Drafting and negotiating any type of contract</span>
                                </label>
                                <div className="accordion__content overflow-hidden bg-[white] font-pop text-black text-[13px]">
                                    <p className="accordion__body leading-6 font-normal p-4 text-justify" id="panel3">
                                        Our lawyers have the expertise to draft any type of contact, agreement and other legal
                                        document needed for your transaction. Whether it is service, lease, loan, credit,
                                        donation, sale and purchase contract we will best negotiate with the counterparty and
                                        customize legal document according to case specifics. We seek to enhance our client’s
                                        position in each relationship entered by him/her. By guaranteeing your rights upfront
                                        you feel protected and avoid any hurdles to be arisen further.
                                    </p>
                                </div>
                            </div>
                            <div className="">
                                <input type="checkbox" name="panel" id="panel-4" className="hidden" />
                                <label htmlFor="panel-4" className="relative flex space-x-3 bg-linkColor font-pop text-[14px] sm:text-[17px] p-4 height-[60px] Label__BTN text-prottoText font-montserrat font-bold text-xl max-lg:text-lg">
                                    <img className="max-lg:hidden" src={AccordionIMG4} alt=""></img>
                                    <span>Assistance in starting your business</span>
                                </label>
                                <div className="accordion__content overflow-hidden bg-[white] font-pop text-black text-[13px]">
                                    <p className="accordion__body leading-6 font-normal p-4 text-justify" id="panel3">
                                        As a beginner in business, people always need a corporate lawyer to guide them in
                                        business set-up arrangements. We consult our clients from legal, business, financial and
                                        tax perspective: from the procedure of company establishment to corporate structuring
                                        and tax optimization. We initiate and accomplish all legal components of business.
                                    </p>
                                </div>
                            </div>
                            <div className="">
                                <input type="checkbox" name="panel" id="panel-5" className="hidden" />
                                <label htmlFor="panel-5" className="relative flex space-x-3 bg-linkColor font-pop text-[14px] sm:text-[17px] p-4 height-[60px] Label__BTN text-prottoText font-montserrat font-bold text-xl max-lg:text-lg">
                                    <img className="max-lg:hidden" src={AccordionIMG5} alt=""></img>
                                    <span>Representation in courts</span>
                                </label>
                                <div className="accordion__content overflow-hidden bg-[white] font-pop text-black text-[13px]">
                                    <p className="accordion__body leading-6 font-normal p-4 text-justify" id="panel3">
                                        Litigation can be extremely confusing and complicated. A seasoned attorney is needed to
                                        help navigate this process for you. Presentation of your case in courts will be carried
                                        out by our experienced professionals. Our team of experts will ensure that you have the
                                        best legal representation in court guaranteeing maximum protection and promotion of your
                                        interests.

                                        We provide legal representation in all instances of local and international courts for
                                        civil, administrative, bankruptcy or other proceedings. This includes arbitration and
                                        representation in the dispute settlement phases as well.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative max-lg:static mt-32">
                <main className="max-lg:before:hidden meet__bg bg-no-repeat bg-cover bg-[center] max-lg:h-auto max-lg:p-10 w-full h-[575px] mt-20 flex justify-center items-center">
                    <div className="w-1/2 text-center max-lg:w-full">
                        <h2 className="flex justify-center items-center font-montserrat font-bold text-xl max-lg:text-lg text-white">
                            <img className="mr-2" src={WhatIMG} alt="" />
                            LET’S MEET.
                        </h2>
                        <h2 className="font-montserrat font-bold text-4xl text-white mt-2">
                            SPEAK WITH OUR EXPERT NOW.
                        </h2>
                        <p className="font-montserrat text-white font-medium mt-8">
                            For more information about what we do for individuals and businesses, please contact
                            us to arrange an initial consultation. To speak with one of our experts,
                            call +(374) 60730740 or schedule a call online today.
                        </p>
                    </div>
                </main>
                <div className="bg-linkColor h-20 w-full" />
            </section>
        </>
    );
}

export default Individuals

