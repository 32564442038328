

function PractiseAreaSingle() {
    return (
        <>
            <section className="about__bg bg-cover bg-[center] h-[500px] w-full flex items-center relative max-lg:static max-lg:justify-center">
                <h2 className="font-montserrat font-semibold max-lg:text-2xl max-lg:mt-0 text-4xl text-white border-b-4 pb-1 border-linkColor max-lg:static absolute left-80 mt-32">
                    Accounting &amp; Bookkeeping</h2>
            </section>
            <section className="flex mt-20 max-w-screen-xl mx-auto space-x-10 max-lg:block max-lg:space-x-0 max-lg:px-10 max-lg:mt-10">
                <div className="w-[30%] max-lg:w-full max-lg:mb-5">
                    <form action='true' className="font-montserrat space-y-5">
                        <input name="true" type="text" className="w-full h-10 p-2 rounded-none border border-gray-700" placeholder="Name" required />
                        <input name="true" type="email" className="w-full h-10 p-2 rounded-none border border-gray-700" placeholder="example@mail.com" required />
                        <input name="true" type="tel" className="w-full h-10 p-2 rounded-none border border-gray-700" placeholder="Phone" required />
                        <textarea name="true" className="w-full p-2 rounded-none border border-gray-700" placeholder="How Can We Help?" cols={40} rows={10} required defaultValue={""} />
                        <input name="true" type="submit" className="font-montserrat bg-linkColor px-5 py-2 mt-3 text-white cursor-pointer transition-all duration-300 hover:bg-prottoBG" defaultValue="Send" />
                    </form>
                </div>
                <div className="w-[70%] max-lg:w-full font-montserrat">
                    <p className="font-medium">
                        Protto Business offers professional bookkeeping and accounting services to the companies who are
                        searching for quality services and outsourcing their company accounts. With our experience in accounting
                        and tax matters in Armenia, we will work to keep your financials in proper order.
                        <br />
                        <br />
                        We will help manage the financial statements and reports of your business. By choosing us as your
                        accountants, you will receive the knowledge essential to business owners for the growth of their
                        organization through properly presented and accurately prepared management accounts.
                    </p>
                    <h2 className="font-bold text-lg mt-5">What We Do</h2>
                    <div className="px-10">
                        <ul className="list-disc list-outside mt-5 font-medium space-y-3 text-[18px] max-lg:text-sm">
                            <li>Maintenance and bookkeeping of company accounts;</li>
                            <li>Presenting accurate financial reports of your business at predefined time intervals;</li>
                            <li>Supporting to your external accountants for their performance enhancement;</li>
                            <li>Prepare professionally controlled figures that are in correct order;</li>
                            <li>Preparation and presentation of year-end reports;</li>
                            <li>Calculation of monthly payables;</li>
                            <li>Submission of monthly relevant tax reports to the local tax authorities;</li>
                            <li>Preparation of account receivables/payables in the online banking for execution;</li>
                            <li>Drafting and submitting relevant invoices/payment orders for clients.</li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PractiseAreaSingle