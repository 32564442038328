import { Link } from "react-router-dom";


function Contact() {
    return (
        <>
            <section className="about__bg bg-cover bg-[center] h-[500px] w-full flex justify-center items-center">
                <h2 className="font-montserrat font-semibold text-6xl text-white">Contact Us</h2>
            </section>
            <section className="flex max-lg:flex-col-reverse max-w-screen-xl max-lg:space-x-0 max-lg:p-10 space-x-5 mx-auto max-lg:mt-10 mt-20">
                <div className="w-2/3 max-lg:w-full">
                    <iframe title="map" className="w-full h-[300px] invert-[1] grayscale-[100%]" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3048.620729582601!2d44.51794211531392!3d40.17299737835073!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abcf4136c35bb%3A0xc8f38472dde51a06!2zMjMsIDIg1LXWgNW-1aHVttWkINWU1bjVudWh1oDVqyDWg9W41bLVuNaBLCDUtdaA1ofVodW2LCDVgNWh1bXVodW91b_VodW2!5e0!3m2!1shy!2s!4v1675437893374!5m2!1shy!2s" style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                </div>
                <div className="w-1/3 max-lg:w-full max-lg:mb-5">
                    <ul className="w-full">
                        <li className="hover:text-linkColor transition-all duration-300">
                            <Link className="fill-linkColor space-x-3 flex" to={'tel:+374 60730740'}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="linkColor" viewBox="0 0 24 24" strokeWidth="1.5" stroke="none" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                </svg>
                                <span className="font-montserrat font-medium">+374 60730740</span>
                            </Link>
                        </li>
                        <div className="w-full h-[1px] border border-gray-300 my-5" />
                        <li className="hover:text-linkColor transition-all duration-300">
                            <Link className="stroke-linkColor space-x-3 flex" to={'mailto:info@protto.com'}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="linkColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z" />
                                </svg>
                                <span className="font-montserrat font-medium">info@protto.com</span>
                            </Link>
                        </li>
                        <div className="w-full h-[1px] border border-gray-300 my-5" />
                        <li className="hover:text-linkColor transition-all duration-300">
                            <Link className="stroke-linkColor space-x-3 flex" target={'_blank'} to={'https://google.com/maps/place/23,+2+Երվանդ+Քոչարի+փողոց,+Երևան,+Հայաստան/@40.1729974,44.5179421,17z/data=!3m1!4b1!4m5!3m4!1s0x406abcf4136c35bb:0xc8f38472dde51a06!8m2!3d40.1729933!4d44.5201308'}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="linkColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" />
                                </svg>
                                <span className="font-montserrat font-medium">23/2 Yervand Kochar street, Yerevan, RA</span>
                            </Link>
                        </li>
                    </ul>
                    <button className="bg-prottoBG text-white font-montserrat font-bold hover:bg-prottoHover transition-all duration-300 rounded-[5px] mt-5 py-3 px-10">
                        <Link className="py-3 px-10" to={'https://calendly.com/appointmentprotto/30min?month=2023-02'} target={'_blank'}>SCHEDULE A CALL</Link>
                    </button>
                </div>
            </section>
        </>
    );
}

export default Contact