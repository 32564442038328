import { Link } from 'react-router-dom';
import MeetIMG from '../assets/images/asd1-1-1.png'

function Business() {
    return (
        <>
            <section className="individuals__bg bg-cover bg-[center] h-[500px] w-full flex justify-center items-center">
                <h2 className="font-montserrat font-extralight text-6xl text-white max-lg:text-2xl">WHAT WE DO FOR <span className="border-linkColor border-b-8 max-lg:border-b-4">BUSINESS</span></h2>
            </section>
            <section className="max-w-screen-xl mx-auto mt-20 max-lg:px-10">
                <div className="flex space-x-5 max-lg:block max-lg:space-x-0">
                    <div className="w-2/3 max-lg:w-full font-montserrat font-medium">
                        <p>
                            Our job is to protect your business so that you can focus on running and growing your business. We
                            do this by seeing around corners for you to identify your risks. We do this by helping companies
                            manage their legal and financial practices effectively in light of the constantly shifting
                            regulations.
                            <br />
                            <br />
                            We offer a comprehensive range of legal and business advisory services that cover all the practice
                            areas that companies require to manage and grow their businesses. Our clients include a diverse
                            group of institutions and individuals: from pharmaceutical and medical device companies to consumer
                            product companies to private foundations and charities to leading food and beverage manufacturers;
                            from foreign companies seeking to transact business in Armenia to foreign companies doing business
                            abroad.
                        </p>
                    </div>
                    <div className="w-1/3 max-lg:w-full max-lg:mt-5">
                        <div className="w-full h-full">
                            <div className="w-full mb-2">
                                <Link className="protto__link" to={'/individuals'}>
                                    What we do for
                                    <span className="protto__span">Individuals
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                        </svg>
                                    </span>
                                </Link>
                            </div>
                            <div className="w-full mb-2">
                                <Link className="protto__link" to={'/business'}>
                                    What we do for
                                    <span className="protto__span">Bussinesses
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                        </svg>
                                    </span>
                                </Link>
                            </div>
                            <div className="w-full max-lg:mb-2">
                                <Link className="protto__link" to={'/clients'}>
                                    What Our
                                    <span className="protto__span">Clients Say
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                        </svg>
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-10">
                    <h2 className="flex font-montserrat font-medium text-xl max-lg:text-lg">
                        <img className="mr-2" src={MeetIMG} alt="" />
                        WHAT WE DO
                    </h2>
                    <h2 className="text-3xl font-montserrat mt-5 font-medium">What Our Protto Employment Lawyers Can Do To Help Your
                        Business</h2>
                    <p className="font-montserrat font-medium">
                        <br />
                        We advise clients on a wide variety of corporate matters, such as private and public M&amp;A, disposals,
                        joint ventures, restructurings, start-ups, as well as general commercial, compliance and corporate
                        governance issues. Our attorneys bring a vast breadth of knowledge and experience to the resolution of
                        local and international disputes, including practice at the International Court of Arbitration.
                        <br />
                        <br />
                        At Protto, lawyers, accountants and business consultants work together seamlessly, co-operating in such
                        a way that the business owners don’t have to get involved in unnecessary complexities. We specifically
                        provide a joined-up legal and accounting service which makes matters much simpler and easier for our
                        clients. This is particularly important given the world is becoming increasingly difficult to understand
                        and navigate.
                    </p>
                </div>
                <div className="flex space-x-5 mt-10 max-lg:block max-lg:space-x-0">
                    <div>
                        <button className="bg-prottoBG max-lg:w-full text-white font-montserrat font-bold hover:bg-prottoHover transition-all duration-300 rounded-[5px] mt-5 py-3 px-10">
                            <Link className='py-3 px-10'>OUR LEGAL SERVICES</Link>
                        </button>
                    </div>
                    <div>
                        <button className="bg-prottoBG max-lg:w-full text-white font-montserrat font-bold hover:bg-prottoHover transition-all duration-300 rounded-[5px] mt-5 py-3 px-10">
                            <Link className='py-3 px-10'>OUR BUSINESS ADVISORY SERVICES</Link>             
                        </button>
                    </div>
                </div>
                <div className="mt-10">
                    <h2 className="font-montserrat font-bold text-2xl">SPEAK WITH OUR EXPERT NOW.</h2>
                    <p className="font-montserrat font-medium mt-5">
                        For more information about what we do for individuals and businesses, please contact us to arrange an
                        initial consultation.To speak with one of our experts,call <Link className="text-linkColor" to={'tel:+37460730740'}>+(374) 60730740</Link> or schedule a call online
                        today.
                    </p>
                </div>
            </section>
            <section className="relative max-lg:static mt-40 max-lg:mt-10">
                <main className="max-lg:before:hidden help__bg helpBG__before bg-no-repeat bg-cover bg-[center] max-lg:h-auto max-lg:p-10 w-full h-[575px] mt-20 flex justify-center items-center">
                    <div className="w-1/2 text-center max-lg:w-full">
                        <h2 className="flex justify-center items-center font-montserrat font-bold text-xl max-lg:text-lg text-prottoText">
                            <img className="mr-2" src={MeetIMG} alt="" />
                            LET’S MEET.
                        </h2>
                        <h2 className="font-montserrat font-bold text-4xl text-prottoText mt-2">
                            SPEAK WITH OUR EXPERT NOW.
                        </h2>
                        <p className="font-montserrat font-medium mt-8">
                            For more information about what we do for individuals and businesses, please contact
                            us to arrange an initial consultation. To speak with one of our experts,
                            call +(374) 60730740 or schedule a call online today.
                        </p>
                    </div>
                </main>
                <div className="bg-linkColor h-20 w-full" />
            </section>
        </>
    );
}

export default Business