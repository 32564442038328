import { Link } from "react-router-dom";
import FooterLogo from "../../assets/images/Capture1-removebg-preview-e1657692078729.png"
import ScrollToTop from "../components/footer/scrollToTop";

function Footer() {
    return (
        <>
            <ScrollToTop />
            <footer>
                <div className="max-w-screen-xl mx-auto mt-10 max-lg:px-10">
                    <div className="flex max-lg:flex-wrap">
                        <div className="w-1/4 max-lg:w-1/2 max-lg:mb-3">
                            <h2 className="text-prottoHover font-montserrat font-semibold text-2xl max-lg:text-lg">EXPERTISE</h2>
                            <ul className="mt-5 max-lg:mt-2">
                                <li className="font-montserrat font-medium transition-all duration-300 max-lg:text-sm hover:text-prottoHover">
                                    <Link>INDUSTRIES</Link>
                                </li>
                                <li className="font-montserrat font-medium transition-all duration-300 hover:text-prottoHover">
                                    <Link to={'/practise-area'}>PRACTICE AREAS</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="w-1/4 max-lg:w-1/2 max-lg:mb-3">
                            <h2 className="text-prottoHover font-montserrat font-semibold text-2xl max-lg:text-lg">INSIGHTS</h2>
                            <ul className="mt-5 max-lg:mt-2">
                                <li className="font-montserrat font-medium max-lg:text-sm transition-all duration-300 hover:text-prottoHover">
                                    <Link to={'/blog'}>PUBLICATIONS</Link>
                                </li>
                                <li className="font-montserrat font-medium max-lg:text-sm transition-all duration-300 hover:text-prottoHover">
                                    <Link>NEWS</Link>
                                </li>
                                <li className="font-montserrat font-medium max-lg:text-sm transition-all duration-300 hover:text-prottoHover">
                                    <Link>WEBINARS</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="w-1/4 max-lg:w-1/2">
                            <h2 className="text-prottoHover font-montserrat font-semibold text-2xl max-lg:text-lg">ABOUT US</h2>
                            <ul className="mt-5 max-lg:mt-2">
                                <li className="font-montserrat font-medium max-lg:text-sm transition-all duration-300 hover:text-prottoHover">
                                    <Link to={'/business'}>FOR BUSINESSES</Link>
                                </li>
                                <li className="font-montserrat font-medium max-lg:text-sm transition-all duration-300 hover:text-prottoHover">
                                    <Link to={'/individuals'}>FOR INDIVIDUALS</Link>
                                </li>
                                <li className="font-montserrat font-medium max-lg:text-sm transition-all duration-300 hover:text-prottoHover">
                                    <Link to={'/our-team'}>OUR TEAM</Link>
                                </li>
                                <li className="font-montserrat font-medium max-lg:text-sm transition-all duration-300 hover:text-prottoHover">
                                    <Link>PRO BONO</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="w-1/4 max-lg:w-1/2">
                            <h2 className="text-prottoHover font-montserrat font-semibold text-2xl max-lg:text-lg">CONTACT US</h2>
                            <ul className="mt-5 max-lg:mt-2">
                                <li className="font-montserrat font-medium max-lg:text-sm transition-all duration-300 hover:text-prottoHover">
                                    <Link to={'tel:+37460730740'}>PHONE: +374 60730740</Link>
                                </li>
                                <li className="font-montserrat font-medium max-lg:text-sm transition-all duration-300 hover:text-prottoHover">
                                    <Link to={'mailto:info@protto.am'}>EMAIL: info@protto.com</Link>
                                </li>
                            </ul>
                            <div className="flex space-x-5 mt-3">
                                <Link to={'https://www.instagram.com/protto.legal/'} target={'_blank'} className="text-4xl max-lg:text-2xl text-prottoText transition-all duration-300 hover:text-prottoHover"><i className="fa-brands fa-instagram" /></Link>
                                <Link to={'https://www.facebook.com/Protto.legal/?paipv=0&eav=AfaDJJ4_yo9LsI2ggPgper_iVyK5gIG1yOobCcZySg88ZQ67FrS0HXhxWV2SAxvZ8iA'} target={'_blank'} className="text-4xl max-lg:text-2xl text-prottoText transition-all duration-300 hover:text-prottoHover"><i className="fa-brands fa-facebook-f" /></Link>
                                <Link to={'https://am.linkedin.com/company/protto-legal?trk=public_profile_topcard-current-company&challengeId=AQFS8tcp05FHlwAAAYBPlZ2967Uf8u3aMR2WbftqZn-SYVvcdFbs_6R0SWsCplHuKq6esooAiYhcbCKcJOrVprRehtL9FpdfIw&submissionId=b17088a1-5c1e-e816-b6dd-115a4de73f2c'} target={'_blank'} className="text-4xl max-lg:text-2xl text-prottoText transition-all duration-300 hover:text-prottoHover"><i className="fa-brands fa-linkedin-in" /></Link>
                            </div>
                        </div>
                    </div>
                    <div className="w-full h-10 mt-5 max-lg:h-auto">
                        <div className="w-1/12 max-lg:w-1/2 max-lg:flex max-lg:justify-center max-lg:items-center">
                            <img className="w-full h-full" src={FooterLogo} alt="" />
                        </div>
                    </div>
                    <div>
                        <ul className="flex space-x-5 font-montserrat font-semibold mt-5 max-lg:text-sm max-lg:flex-col max-lg:space-x-0">
                            <li>
                                <Link className="transition-all duration-300 hover:text-prottoHover">PRIVACY POLICY</Link>
                            </li>
                            <li>
                                <Link className="transition-all duration-300 hover:text-prottoHover">COOKIE POLICY</Link>
                            </li>
                            <li>
                                <Link className="transition-all duration-300 hover:text-prottoHover">ACCESSIBILITY</Link>
                            </li>
                            <li>
                                <Link className="transition-all duration-300 hover:text-prottoHover">SITE MAP</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="py-7">
                        <h2 className="max-lg:text-xs flex space-x-2 items-center font-montserrat font-medium ">
                            <i className="fa-regular fa-copyright" />
                            <span>Copyright 2023 Protto Legal. All rights reserved.</span>
                        </h2>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer