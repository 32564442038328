import { Link } from 'react-router-dom';
import PublicationsIMG from '../../../assets/images/Vector-1.svg';
import Publiaction1IMG from '../../../assets/images/ITS-IMPORTANT-TO-KNOW-1-300x251.png'
import Publiaction2IMG from '../../../assets/images/unnamed-300x200.jpg'
import Publiaction3IMG from '../../../assets/images/State-Support-to-the-IT-Businesses-4-300x251.png'

function HomeSixth() {
    return (
        <>
            <section className="max-w-screen-xl mx-auto mt-20 max-lg:px-10">
                <div className="flex justify-center items-center space-x-5">
                    <img className="h-auto w-auto max-lg:hidden" src={PublicationsIMG} alt="" />
                    <h2 className="font-montserrat font-semibold text-5xl text-prottoText max-lg:text-2xl">Publications</h2>
                </div>
                <div className="flex space-x-5 mt-20 max-lg:block max-lg:space-x-0 max-lg:mx-auto">
                    <div className="w-1/3 h-auto max-lg:w-full max-lg:mb-3">
                        <Link to={'/single-post'}>
                            <div className="h-[251px]">
                                <img className="h-full w-full object-cover" src={Publiaction1IMG} alt="" />
                            </div>
                            <h2 className="mt-2 font-montserrat font-bold text-xl p-2 max-lg:text-lg">
                                Important Amendments for Legal Entities
                            </h2>
                        </Link>
                    </div>
                    <div className="w-1/3 h-auto max-lg:w-full max-lg:mb-3">
                        <Link to={'/single-post'}>
                            <div className="h-[251px]">
                                <img className="h-full w-full object-cover" src={Publiaction2IMG} alt="" />
                            </div>
                            <h2 className="mt-2 font-montserrat font-bold text-xl p-2 max-lg:text-lg">
                                Effects of Share Dilution on Your Startup
                            </h2>
                        </Link>
                    </div>
                    <div className="w-1/3 h-auto max-lg:w-full max-lg:mb-3">
                        <Link to={'/single-post'}>
                            <div className="h-[251px]">
                                <img className="h-full w-full object-cover" src={Publiaction3IMG} alt="" />
                            </div>
                            <h2 className="mt-2 font-montserrat font-bold text-xl p-2 max-lg:text-lg">10 BILLION ARMENIAN DRAMS AS
                                STATE SUPPORT TO
                                IT COMPANIES | 10 МЛРД АРМЯНСКИХ ДРАМОВ НА ГОСУДАРСТВЕННУЮ ПОДДЕРЖКУ ИТ-КОМПАНИЙ</h2>
                        </Link>
                    </div>
                </div>
                <button className="bg-prottoBG text-white font-montserrat font-bold hover:bg-prottoHover transition-all duration-300 rounded-[5px] mt-5 py-3 px-10">
                    <Link to={'/blog'} className="py-3 px-10">
                        VIEW ALL
                    </Link>
                </button>
            </section>
        </>
    );
}

export default HomeSixth