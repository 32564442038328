import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function HomeFifth() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
    };
    return (
        <>
            <section className="testimonial__bg h-auto w-full p-20 max-lg:p-10">
                <div className="max-w-screen-xl mx-auto">
                    <h2 className="font-montserrat font-bold text-center text-white text-4xl mb-10 max-lg:text-xl">
                        WHAT OUR CLIENTS SAY
                    </h2>
                    <Slider {...settings}>
                        <div className="h-44 sm:h-48">
                            <div className="w-full h-full mx-auto rounded-xl text-center">
                                <p className="p-3 text-white font-montserrat font-medium">
                                    My experience with Protto Legal has been excellent. My attorney, Feliks, gave my case all
                                    the time and attention that was needed to be successful in court. I very much appreciate the
                                    knowledge and support I received through Protto."
                                </p>
                                <p className="p-3 text-white font-bold font-montserrat">Vardan Vardanyan</p>
                            </div>
                        </div>
                        <div className="h-44 sm:h-48">
                            <div className="w-full h-full mx-auto rounded-xl text-center">
                                <p className="p-3 text-white font-montserrat font-medium">
                                    "Everyone at Protto is professional, knowledgeable and very results-oriented. I highly
                                    recommend this firm especially when it comes to setting up businesses in Armenia."
                                </p>
                                <p className="p-3 text-white font-bold font-montserrat">Dmitriy Tarasov</p>
                                <br />
                                <span className="font-light text-white font-montserrat">Founder at Easy-SMS</span>
                            </div>
                        </div>
                        <div className="h-44 sm:h-48">
                            <div className="w-full h-full mx-auto rounded-xl text-center">
                                <p className="p-3 text-white font-montserrat font-medium">
                                    "Professional and Efficient. The team at Protto executed relocation of my company
                                    expeditiously and fairly. Highly recommend their services."
                                </p>
                                <p className="p-3 text-white font-bold font-montserrat">Sergey Savinov</p>
                                <br />
                                <span className="font-light text-white font-montserrat">CEO and Co-Founder at FitOn</span>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
        </>
    );
}

export default HomeFifth